import { Language, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  useTheme,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/headerHome.jpeg";

import checkToken from "../../services/CheckToken";
import logoMw from "../../assets/logoRoxa.svg";
import logoMw2 from "../../assets/logosSvg/logoMw2.svg";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Login(params) {
  const { setCheckLogin = () => {} } = params;
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  const logo = `${window?.config?.REACT_APP_LOGO_LOGIN_URL || logoMw}`;
  const logoLight = `${window?.config?.REACT_APP_LOGO_LOGIN_URL || logoMw2}`;
  const isPartner = window?.config?.REACT_APP_IS_PARTNER === "true" || false;
  const backgroundImage = `${
    window?.config?.REACT_APP_BACKGROUND_IMAGE_URL || background
  }`;
  const navigate = useNavigate();

  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const styles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    paperImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "60vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
    },
    boxTitlesImage: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    titleImage: {
      fontSize: "3em",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
    },
    subTitleImage: { fontSize: "1em", width: "60%", fontWeight: "300" },
    paperForm: {
      minHeight: "500px",
      minWidth: "330px",
      padding: "40px 80px",
      width: { xs: "100vw", md: "50vw", lg: "45vw" },
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
    },
    boxLogo: {
      padding: 4,
      width: "90%",
      flexDirection: "column",
      alignItems: { xs: "center", smd: "end" },
    },
    logo: { height: "auto", width: "auto" },
    titleForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
      alignSelf: "stretch",
    },
    boxForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: { xs: 0, md: 1 },
      width: { xs: "80vw", md: "100%" },
      maxWidth: "520px",
    },
    stackPhoto: {
      padding: 4,
      width: "90%",
      flexDirection: "column",
      alignItems: { xs: "center", md: "end" },
    },
    boxTextFields: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      gap: 3,
    },
    textField: { width: "100%" },
    boxButtonAndLinks: {
      display: "flex",
      height: "max-content",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
      alignSelf: "stretch",
      mt: 2,
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      marginTop: "15px",
      height: "40px",
      textTransform: "none",
      fontSize: 14,
    },
    boxLink: {
      display: "flex",
      padding: "28px 0px",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: { xs: "column", smd: "row" },
      width: "100%",
      gap: { xs: 2, sm: 1 },
    },
    boxLinkIcons: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      gap: 1,
    },
    icons: { color: "text.primary" },
  };

  useEffect(() => {
    setTimeout(() => {
      tokenValidate();
    }, 200);

    async function tokenValidate() {
      const data = await checkToken();
      let perfilAlreadyExists =
        JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
      let satusPerfil =
        JSON.parse(localStorage.getItem("perfil"))?.status || false;
      if (data && satusPerfil === true && perfilAlreadyExists === true) {
        navigate("/homeIn");
      }
      if (!data) setCheckLogin(false);
    }
  }, []);

  const handleLogin = async () => {
    if (!login || !senha) {
      setNull(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${url}/login`,
        { login, senha },
        { withCredentials: false }
      );

      if (response.data.token) {
        const { perfilCliente, logs, acessoClienteId, ...userData } =
          response.data;
        if (response.data.usuarioCliente.status) {
          localStorage.setItem("1", JSON.stringify(userData));
          if (perfilCliente)
            localStorage.setItem("perfil", JSON.stringify(perfilCliente));
          localStorage.setItem("login", JSON.stringify(login));
          localStorage.setItem("logs", JSON.stringify(logs));
          localStorage.setItem(
            "acessoClienteId",
            JSON.stringify(acessoClienteId)
          );
        }

        if (
          response.data.usuarioCliente.status === true &&
          response.data.perfilAlreadyExists === true
        ) {
          navigate("/homeIn");
        } else if (
          response.data.usuarioCliente.status === true &&
          response.data.perfilAlreadyExists === false
        ) {
          navigate("/accountType");
        } else if (
          response.data.usuarioCliente.status === false &&
          response.data.perfilAlreadyExists === true
        ) {
          setAlert(true);
        }
      } else {
        setAlertOpen(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperImage}>
          {!isPartner && (
            <Box sx={styles.boxTitlesImage}>
              <Typography sx={styles.titleImage}>
                Monitoramento eficiente e confiável.
              </Typography>
              <br></br>
              <Typography sx={styles.subTitleImage}>
                Monitoramento de infraestrutura de redes para provedores de
                internet, com suporte confiável e atencioso.
              </Typography>
            </Box>
          )}
        </Paper>

        <Paper sx={styles.paperForm}>
          <Stack sx={styles.stackPhoto}>
            <Icon sx={styles.logo}>
              <img
                src={colorMode === "light" ? logo : logoLight}
                style={{
                  height: "auto",
                  width: "227px",
                }}
                alt="logo mw"
              />
            </Icon>
          </Stack>
          <Box sx={styles.boxForm}>
            <Box sx={styles.titleForm}>
              <Typography fontSize="2em" color="text.primary" fontWeight={500}>
                Login
              </Typography>
              <Typography fontSize="0.875em" color="text.secondary">
                Bem vindo! Por favor digite suas credenciais.
              </Typography>
            </Box>
            <Box sx={styles.boxTextFields}>
              <TextField
                error={!login && Null}
                type="text"
                onKeyDown={handleKeyDown}
                onChange={(event) => {
                  setLogin(event.target.value);
                }}
                placeholder="Login"
                helperText={!login && Null && "Login Inválido"}
                sx={styles.textField}
              />
              <TextField
                error={!senha && Null}
                type={showPassword ? "text" : "password"}
                onKeyDown={handleKeyDown}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                placeholder="Password"
                helperText={!senha && Null && "Senha Inválida"}
                sx={styles.textField}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => handleClickShowPassword()}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
            <Box sx={styles.boxButtonAndLinks}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  handleLogin();
                }}
                sx={styles.button}
              >
                Entrar
              </Button>
              <Box sx={styles.boxLink}>
                <Typography fontSize="0.875em" color="text.secondary">
                  Não está conseguindo acessar?{" "}
                </Typography>
                <Link
                  href="/resetPassword"
                  sx={{
                    textDecoration: "none",
                    fontSize: "0.875em",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  Redefinir senha
                </Link>
              </Box>
              {!isPartner && (
                <Stack direction={"column"} alignItems={"center"} gap={1}>
                  <Box sx={styles.boxLinkIcons}>
                    <IconButton href="https://www.facebook.com/mwsolucoesmonitoramento/">
                      <FacebookIcon sx={styles.icons} />
                    </IconButton>
                    <IconButton href="https://www.linkedin.com/company/mw-solucoes/">
                      <LinkedInIcon sx={styles.icons} />
                    </IconButton>
                    <IconButton href="https://instagram.com/mwsolucoesltda?igshid=MzRlODBiNWFlZA==">
                      <InstagramIcon sx={styles.icons} />
                    </IconButton>
                    <IconButton href="https://home.mw-solucoes.com/">
                      <Language sx={styles.icons} />
                    </IconButton>
                  </Box>
                  <Typography
                    fontSize={"12px"}
                    sx={{ opacity: "0.8" }}
                    color="text.secondary"
                  >
                    Version: {window?.config?.REACT_APP_COMMIT_HASH || "1.0.0"}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: senha ou login incorreto</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={alert}
        onClose={() => {
          setAlert(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Usuário desativado</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
