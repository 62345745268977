import { alpha, darken, lighten } from "@mui/material";

const useStyles = (theme) => ({
  titlePage: {
    justifyContent: "start",
    color: theme.palette.text.primary,
    fontSize: "1.1rem",
    textTransform: "none",
    ml: "10px",
  },
  boxOP: {
    border: "1px solid " + theme.palette.background.paperLinkedMaps,
    p: 2,
    borderRadius: "5px",
  },
  text: {
    fontSize: "0.95rem",
    fontWeight: 500,
  },
  textFine: {
    fontSize: "0.86rem",
    mt: "-2px",
    color: theme.palette.text.shadow,
  },

  textAdorment: {
    ml: "-5px",
    fontSize: "12px",
    fontWeight: 800,
    color: theme.palette.text.disabled,
    width: "100%",
  },
  subTitle: {
    width: "170px",
    fontSize: "0.85rem",
    fontWeight: 400,
  },
  pageTitle: {
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.96rem",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    color: theme.palette.primary.main,
    textAlign: "center",

    //borderBottom: "1px solid" + theme.palette.color.appBarBorder,
  },
  accodion: {
    width: "100%",
  },
  accodionSumary: {
    height: "60px",
  },
  tabs: {
    "& button": {
      fontWeight: 400,
      textTransform: "none",
      minHeight: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      pl: "20px",
      gap: 0.5,
    },

    "& button.Mui-selected": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: "0px 0px 5px  #0000000a",
      fontWeight: 500,
    },
    "& button:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.4),
    },
  },

  textField: {
    fontSize: "14px",
    width: "100%",

    "& .MuiInputBase-input": {
      fontSize: "0.9rem",
      height: "20px!important",
    },

    '& input[type="number"]::-webkit-outer-spin-button': {
      display: "none",
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: "none",
    },
    '& input[type="number"]': {
      MozAppearance: "textfield",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.background.paperLinkedMaps,
      },
      "&.Mui-focused": {
        boxShadow: "none",
        border: "none",
      },
    },
  },

  switch: {
    ml: "0px",
    mr: "0px",
    p: "10px",
    width: "250px",
    borderRadius: "5px",

    backgroundColor: theme.palette.background.paperLinkedMaps,
  },
  containerData: {
    padding: "15px 10px 0px 20px", //top right bottom left
    width: "100%",

    //backgroundColor: theme.palette.background.default,
  },
  containerTab: {
    width: "300px",
    p: "15px 0px",
    borderRight: "1px solid" + theme.palette.color.appBarBorder,
    backgroundColor: theme.palette.background.default,
  },
  container: {
    backgroundColor: alpha(theme.palette.background.paper, 0.98),
    display: "flex",
    flexDirection: "row",
    maxWidth: "1000px",
    minWidth: "800px",
    height: "600px",
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  buttomEnd: {
    textTransform: "none",
  },
  buttonEscalaConfig: {
    textTransform: "none",
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",

    backgroundColor: theme.palette.background.paperLinkedMaps,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  barEscala: {
    mt: 2,
    minHeight: "25px",
    flexDirection: "row",
  },
  boxEscala: {
    minHeight: "15px",
    minWidth: "60px",
    ml: "-1px",
  },
  buttonEscala: {
    p: "0px",
    m: "0px",
    minWidth: "0px",
  },
  iconEscala: {
    color: theme.palette.color.appBarBorder,
    transform: "rotate(30deg)",
    fontSize: "30px",
    mb: "-11px",
  },
  paperEscala: {
    border: "1px solid" + theme.palette.color.appBarBorder,
    width: "300px",
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    backgroundColor: theme.palette.background.paper,
  },
  titleEscala: {
    minWidth: "50px",
    fontSize: "0.9rem",
  },
  textFieldEscala: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      boxShadow: "none",
    },
  },
  textFieldEscalaNumber: {
    "& input[type=number]": {
      MozAppearance: "textfield", // Remove as setas no Firefox
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      WebkitAppearance: "none", // Remove as setas no Chrome, Safari e Edge
      margin: 0,
    },
  },
  alert: {
    width: "500px",
    background: theme.palette.mode == "dark" ? "#123746" : "#8fdfffa9",
    padding: "10px",
    borderRadius: "3px",
    mt: "10px",
    mb: "-10px",
  },
  alertError: {
    width: "500px",
    background: theme.palette.primary.main,
    padding: "10px",
    borderRadius: "3px",
    mt: "10px",
    mb: "-10px",
  },

  popoverEscala: {
    "& .MuiPopover-paper": {
      width: "fix-content",
      backgroundColor: "transparent",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
});
export default useStyles;
