import { Box, Grid2 } from "@mui/material";
import { ReactComponent as FlowSvg } from "../../assets/logosSvg/logoEasyFlow.svg";
import { ReactComponent as MomSvg } from "../../assets/logosSvg/logoEasyMon.svg";
import { ReactComponent as BiSvg } from "../../assets/logosSvg/logoEasyBI.svg";
import { ReactComponent as ReportSvg } from "../../assets/logosSvg/easyReport.svg";

import { ReactComponent as MapsSvg } from "../../assets/logosSvg/logoEasyMaps.svg";
import { ReactComponent as WatchSvg } from "../../assets/logosSvg/logoEasyWatch.svg";
import { ReactComponent as ShopSvg } from "../../assets/logosSvg/logoMwShop.svg";

import { ReactComponent as ZabbixLogo } from "../../assets/logosSvg/logoZabbix.svg";
import MyCard from "./MyCard";

import Inbox from "./inbox/inbox";
import { useTheme } from "@emotion/react";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  cardGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
    width: "100%",
  },
  body: {
    gap: "1.5rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  sizeGridContainer: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
  sizeGridItem: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  },
};

export default function NocLinks() {
  const theme = useTheme();
  const easyFlowLogo = `${window?.config?.REACT_APP_LOGO_EASY_FLOW_URL || ""}`;
  const easyMonLogo = `${window?.config?.REACT_APP_LOGO_EASY_MON_URL || ""}`;
  const easyBiLogo = `${window?.config?.REACT_APP_LOGO_EASY_BI_URL || ""}`;
  const easyReportLogo = `${
    window?.config?.REACT_APP_LOGO_EASY_REPORT_URL || ""
  }`;
  const easyMapsLogo = `${window?.config?.REACT_APP_LOGO_EASY_MAPS_URL || ""}`;
  const isPartner = window?.config?.REACT_APP_IS_PARTNER === "true" || false;
  const linkEasyBI =
    window?.config?.REACT_APP_GRAFANA_LINK?.split("/login") || "";

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Grid2
          container
          rowSpacing={1.5}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1.5}
          flexWrap={"wrap"}
          wrap="wrap"
          sx={{ width: "100%" }}
          columns={styles.sizeGridContainer}
        >
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              link={window?.config?.REACT_APP_GRAFANA_LINK || ""}
              img={<MomSvg />}
              clientImg={easyMonLogo}
              bgcolor={theme.palette.background.paper}
              circleColorOne={"#E65835"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid2>
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              img={<FlowSvg />}
              clientImg={easyFlowLogo}
              bgcolor={theme.palette.background.paper}
              link={window?.config?.REACT_APP_GRAFANA_LINK || ""}
              circleColorOne={"#2DC989"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid2>
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              img={<ZabbixLogo />}
              size={"small"}
              bgcolor={theme.palette.background.paper}
              link={window?.config?.REACT_APP_ZABBIX_LINK || ""}
              circleColorOne={"#F43F59"}
              circleColorTwo={"#822e87"}
            />
          </Grid2>
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              img={<BiSvg />}
              clientImg={easyBiLogo}
              size={"medium"}
              bgcolor={theme.palette.background.paper}
              link={`${linkEasyBI[0]}/HOME_EASYBI` || ""}
              circleColorOne={"#2DC989"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid2>
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              img={<ReportSvg />}
              clientImg={easyReportLogo}
              bgcolor={theme.palette.background.paper}
              target=""
              link={"/easyReport"}
              circleColorOne={"#F48710"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid2>
          <Grid2 item size={styles.sizeGridItem}>
            <MyCard
              img={<MapsSvg />}
              clientImg={easyMapsLogo}
              bgcolor={theme.palette.background.paper}
              target=""
              link={"/easyMaps"}
              circleColorOne={"#EA4860"}
              circleColorTwo={"#2dc988"}
            />
          </Grid2>
          {!isPartner && (
            <>
              <Grid2 item size={styles.sizeGridItem}>
                <MyCard
                  img={<WatchSvg />}
                  bgcolor={theme.palette.background.paper}
                  target=""
                  link={"/easyWatch"}
                  circleColorOne={"#E9603B"}
                  circleColorTwo={"#AA3F76"}
                />
              </Grid2>
              <Grid2 item size={styles.sizeGridItem}>
                <MyCard
                  img={<ShopSvg />}
                  bgcolor={theme.palette.background.paper}
                  link={"iot/shopping"}
                  circleColorOne={"#3EACE2"}
                  circleColorTwo={"#AA3F76"}
                />
              </Grid2>
            </>
          )}
        </Grid2>
        <Inbox />
      </Box>
    </Box>
  );
}
