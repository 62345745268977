import { AccessTimeOutlined, History, Paragliding } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  Paper,
  Stack,
  Typography,
  Chip,
  Box,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  Icon,
  Button,
} from "@mui/material";

import mwIcon from "../../../assets/logo4.png";
import styles from "./styles";
import { useState, useEffect } from "react";
//import api from "../../../services/api";
import apiSistema from "../../../services/apiSistema";

export default function Inbox(props) {
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [rows, setRows] = useState([]);
  const acesso = JSON.parse(localStorage.getItem("acessoClienteId")) || {};
  const token = window?.config?.REACT_APP_MW_SISTEMA_TOKEN || "";
  const logo = `${window?.config?.REACT_APP_LOGO_SMALL_URL || mwIcon}`;

  useEffect(() => {
    //funcao para chamar a api de atualizacoes
    const acessoClienteId = JSON.parse(localStorage.getItem("acessoClienteId"));
    handleApiGetAtualizacoes(acessoClienteId);
  }, [localStorage.getItem("acessoClienteId")]);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "campo copiado!",
      severity: "info",
    });
  };
  function handleRows(params) {
    const pushRows = params.map((dataRow) => {
      let newObj = {};
      newObj.id = dataRow.id;
      newObj.responsavel = dataRow.usuario ? dataRow.usuario.Perfil : "";
      newObj.solicitante = dataRow.usuarioCliente
        ? dataRow.usuarioCliente.PerfilCliente
        : "";
      newObj.codigo = dataRow.codigo;
      newObj.descricao = dataRow.descricao;
      newObj.categoria = dataRow.categoria;
      newObj.subCategoria = dataRow.subCategoria;
      newObj.status = dataRow.status;
      newObj.previsao = dataRow.previsao;
      //newObj.data = new Date(new Date(dataRow.createAt) - 3 * 60 * 60 * 1000); //coreção de fuso horario, com diferença de 3 horas
      if (dataRow.updateAt) {
        newObj.tempo = handleTime(dataRow.updateAt);
      } else {
        newObj.tempo = handleTime(dataRow.createAt);
      }
      newObj.updateAt = dataRow.updateAt;
      return newObj;
    });
    setRows(pushRows);
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }
  async function handleApiGetAtualizacoes(acesso) {
    try {
      setLoadingPage(true);
      const response = await apiSistema.get("/suporte/atualizacoes/findMany", {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-acess-id": `${acesso}`,
        },
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setLoadingPage(false);
        if (response.data) {
          handleRows(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingPage(false);
    }
  }

  return (
    <Paper sx={styles.container} elevation={3}>
      <Stack alignItems={"center"}>
        <Typography sx={styles.title}>Caixa de Entrada</Typography>
      </Stack>
      {loadingPage && (
        <Paper variant="outlined" sx={styles.paperBody}>
          <Box
            sx={{
              m: 1,
              position: "relative",
              marginLeft: "47%",
              marginTop: "11%",
              marginBottom: "11%",
            }}
          >
            <Icon style={{ width: "80px", height: "auto" }}>
              <img src={logo} style={{ width: "80px", height: "auto" }} />
            </Icon>

            <CircularProgress
              size={100}
              sx={{
                color: "primary",
                position: "absolute",
                top: -9,
                left: -6,
                zIndex: 1,
              }}
              thickness={2.5}
            />
          </Box>
        </Paper>
      )}
      {!loadingPage && (
        <Paper variant="outlined" sx={styles.paperBody}>
          {rows.map((row, index) => {
            return (
              <Box key={index}>
                <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
                  <Stack gap={1} width={"100%"}>
                    <Stack
                      direction={"row"}
                      gap={"16px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"column"}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"16px"}
                          flexWrap={"wrap"}
                        >
                          <Stack>
                            <Typography sx={styles.inboxSubtitle}>
                              Descrição:
                            </Typography>
                            <Typography sx={styles.inboxTextDescricao}>
                              {row.descricao}
                            </Typography>
                          </Stack>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Avatar
                              sx={{ bgcolor: "orangered" }}
                              src={row.responsavel.foto}
                            >
                              {row.responsavel.nome
                                ? row.responsavel.nome[0].toUpperCase()
                                : "U"}
                            </Avatar>
                            <Box>
                              <Typography sx={styles.inboxSubtitle}>
                                Responsável:
                              </Typography>
                              <Typography sx={styles.inboxTextName}>
                                {row.responsavel.nome
                                  ? row.responsavel.nome
                                  : "Indefinido"}
                              </Typography>
                            </Box>
                          </Stack>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Avatar
                              sx={{ bgcolor: "green" }}
                              src={row.solicitante.foto}
                            >
                              {row.solicitante?.nomeFantasia
                                ?.trim()
                                .toUpperCase()[0] ||
                                row.solicitante?.nome
                                  ?.trim()
                                  .toUpperCase()[0] ||
                                "U"}
                            </Avatar>
                            <Box>
                              <Typography sx={styles.inboxSubtitle}>
                                Solicitante:
                              </Typography>
                              <Typography sx={styles.inboxTextName}>
                                {row.solicitante?.nome ||
                                  row.solicitante?.nomeFantasia ||
                                  "Indefinido"}
                              </Typography>
                            </Box>
                          </Stack>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <Stack>
                            <Typography sx={styles.inboxSubtitle}>
                              Categoria:
                            </Typography>
                            <Typography sx={styles.inboxTextCategoria}>
                              {row.categoria ? row.categoria : "SEM CATEGORIA"}
                            </Typography>
                          </Stack>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Stack>
                            <Typography sx={styles.inboxSubtitle}>
                              Subcategoria:
                            </Typography>
                            <Typography sx={styles.inboxTextSubcategoria}>
                              {row.subCategoria
                                ? row.subCategoria.split("-")[1]
                                : "SEM SUBCATEGORIA"}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Box sx={styles.boxDemanda}>
                          <History fontSize="small" sx={styles.history} />
                          <Typography sx={styles.demandaText}>
                            Esta demanda foi{" "}
                            {!row.updateAt
                              ? "criada"
                              : row.status === 2
                              ? "concluída"
                              : "atualizada"}
                            .
                          </Typography>
                        </Box>
                      </Stack>
                      <Box sx={styles.boxStatus}>
                        <Typography sx={styles.inboxSubtitle}>
                          Status:
                        </Typography>
                        {row.status === 0 ? (
                          <>
                            <Chip
                              label={"Pendente"}
                              sx={{ bgcolor: "chip.pending", color: "white" }}
                            />
                          </>
                        ) : row.status === 1 ? (
                          <>
                            <Chip
                              label={"Em andamento"}
                              sx={{
                                bgcolor: "chip.inProgress",
                                color: "white",
                              }}
                            />
                          </>
                        ) : row.status === 2 ? (
                          <>
                            <Chip
                              label={"Concluída"}
                              sx={{ bgcolor: "chip.completed", color: "white" }}
                            />
                          </>
                        ) : row.status === 3 ? (
                          <>
                            <Chip
                              label={"Aguardando revisão"}
                              sx={{
                                bgcolor: "chip.waitingReview",
                                color: "white",
                              }}
                            />
                          </>
                        ) : row.status === 4 ? (
                          <>
                            <Chip
                              label={"Aguardando retorno"}
                              sx={{
                                bgcolor: "chip.waitingReturn",
                                color: "white",
                              }}
                            />
                          </>
                        ) : row.status === 5 ? (
                          <>
                            <Chip
                              label={"Arquivada"}
                              sx={{
                                bgcolor: "chip.arquive",
                                color: "white",
                              }}
                            />
                          </>
                        ) : null}
                        <Box sx={styles.tooltipBoxStatus}>
                          <Tooltip
                            title="Clique para copiar o código"
                            onClick={() => {
                              handleCopy(row.codigo);
                            }}
                          >
                            <Typography sx={styles.statusText}>
                              cod: {row.codigo}
                            </Typography>
                          </Tooltip>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <Typography sx={styles.statusText}>
                            {row.tempo}
                          </Typography>
                          <AccessTimeOutlined
                            fontSize="small"
                            sx={{ opacity: "50%" }}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
                {!(index === rows.length - 1) && (
                  <Divider sx={styles.divider} />
                )}
              </Box>
            );
          })}
        </Paper>
      )}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Paper>
  );
}
