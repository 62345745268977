import {
  Box,
  Collapse,
  Drawer,
  Icon,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CustomTreeView from "./customTreeView";
import { cloneElement, useEffect, useState } from "react";
export default function SideBar(props) {
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const {
    openRightBar,
    setOpenRightBar,
    openDrawer,
    setOpenDrawer,
    iconLogo,
    clientLogo,
    items,
    ocultar = false,
    backgroundColor = null,
    titlePage = "",
    setTitlePage = () => {},
    link = "",
    setLink = () => {},
  } = props;

  const handleDrawerToggle = () => {
    setOpenDrawer((prevState) => !prevState);
  };
  const [openPaper, setOpenPaper] = useState(false);
  // useEffect(() => {
  //   if (!openRightBar) {
  //     setOpenPaper(false);
  //   }
  // }, [openRightBar]);
  const widthRightBar = (open) => (open ? 270 : 56);
  const styles = {
    paper: {
      //height: "100vh",
      // overflowX: "auto",

      height: "calc( 100vh - 65px )",
      position: "sticky",
      top: 65,
      left: 0,
      zIndex: 1,
      display: { xs: "none", lg: "flex" },
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "none",
      borderRadius: 0,
      backgroundColor: backgroundColor,
    },
    boxTreeView: {
      height: "fit-content",
      overflow: "auto",
    },
    drawer: {
      display: { xs: "inline", lg: "none" },
    },
    icons: {
      color:
        colorMode === "light"
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.7)",
    },
    containerOutlet: { flexGrow: 1, paddingTop: 5, paddingInline: 3 },
    buttonMenuDawer: {
      //color: "white",
    },
    boxMenuAndLogo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingX: 1,
      gap: 1,
      width: "270px",
    },
    iconLogo: {
      width: "fit-content",
      height: "60px",
      margin: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    imgIcon: {
      width: 170,
      height: "60px",
    },
  };
  const customIconLogo = cloneElement(iconLogo, {
    style: {
      width: "170px",
      fill: theme.palette.text.primary,
    },
    //className: "custom-svg",
    role: "img",
  });
  return (
    <>
      <Drawer
        variant="temporary"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={styles.drawer}
      >
        <>
          <Box sx={styles.boxMenuAndLogo}>
            <IconButton
              sx={styles.buttonMenuDawer}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            {clientLogo ? (
              <Icon sx={styles.iconLogo}>
                {" "}
                <img src={clientLogo} style={styles.imgIcon} />
              </Icon>
            ) : (
              <Icon sx={styles.iconLogo}>{customIconLogo}</Icon>
            )}
          </Box>
          <CustomTreeView
            ocultar={ocultar}
            open={openDrawer}
            setOpen={setOpenDrawer}
            closeOnClickItem
            titlePage={titlePage}
            setTitlePage={setTitlePage}
            setLink={setLink}
            {...props}
          />
        </>
      </Drawer>
      {!ocultar && (
        <Paper
          sx={styles.paper}
          variant="outlined"
          onMouseEnter={() => setOpenPaper(true)}
          onMouseLeave={() => setOpenPaper(false)}
        >
          <Collapse
            in={openRightBar ? openRightBar : openPaper}
            //in={openRightBar}
            orientation="horizontal"
            collapsedSize={widthRightBar(false)}
            timeout={300}
          >
            <Box sx={{ height: "calc( 100vh - 65px)", overflow: "auto" }}>
              <CustomTreeView
                ocultar={ocultar}
                open={openRightBar ? openRightBar : openPaper}
                //open={openRightBar}
                setOpen={setOpenRightBar}
                titlePage={titlePage}
                setTitlePage={setTitlePage}
                setLink={setLink}
                {...props}
              />
            </Box>
          </Collapse>
        </Paper>
      )}
    </>
  );
}
