import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
const MaisDetalhesFtth = ({ conexao, theme, styles, formatString }) => {
  function isBoolean(data) {
    if (data == "1" || data == 1 || data == "0" || data == 0) return true;
    else return false;
  }
  return (
    Object.keys(conexao?.data || []).filter((a) => a != "potencia").length >
      0 && (
      <Accordion
        key={"outrosItensElemnteSeleted"}
        disableGutters
        sx={{
          backgroundColor: theme.palette.background.paperLinkedMaps,
          boxShadow: "none",
          "&::before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh"
        >
          <Typography sx={styles.textHeaderAco}>Mais detalhes</Typography>
        </AccordionSummary>

        <AccordionDetails>
          {Object.keys(conexao?.data || [])
            .filter(
              (a) =>
                a != "localizacao" &&
                a != "potencia" &&
                a != "status" &&
                a != "consumo"
            )
            .map((nick, index) => (
              <Fragment key={"dadosCliente" + index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Typography
                    sx={styles.textAlerta}
                    fontSize={"0.7rem"}
                    width="170px"
                  >
                    {formatString(nick)}
                  </Typography>

                  <Stack direction={"row"} gap={"2px"}>
                    {isBoolean(conexao?.data?.[nick]) &&
                    conexao?.data?.[nick]?.unit == null ? (
                      <Typography
                        sx={{
                          ...styles.textHeaderAco,
                          textTransform: "lowercase",
                          ...{
                            color: Boolean(conexao?.data?.[nick]?.value)
                              ? theme.palette.color.alerts.success
                              : theme.palette.color.alerts.error,
                          },
                        }}
                      >
                        {Boolean(conexao?.data?.[nick]?.value)
                          ? "Ativo"
                          : "Inativo"}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            ...styles.textHeaderAco,
                            textTransform: "lowercase",
                            textAlign: "end",
                          }}
                        >
                          {conexao?.data?.[nick]?.value}
                        </Typography>
                        <Typography
                          sx={{
                            ...styles.textHeaderAco,
                            textTransform: "lowercase",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {conexao?.data?.[nick]?.unit}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Fragment>
            ))}{" "}
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default MaisDetalhesFtth;
