import React, { useCallback, useEffect, useState } from "react";
import {
  EdgeLabelRenderer,
  getBezierPath,
  getSimpleBezierPath,
  getSmoothStepPath,
  getStraightPath,
  useReactFlow,
  useStore,
} from "reactflow";
import ClickableBaseEdge from "./clickableBaseEdge";
import { useTheme } from "@mui/material";
import { convertBitsToBytes } from "../../../utils/easyMaps/convertToBytes";
import { useOutletContext } from "react-router-dom";
export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY },
  offset
) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX} ${
    centerY + offset
  } ${targetX} ${targetY}`;
};

export default function PositionableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  source,
  target,
  markerEnd,
  data,
  selected,
}) {
  const theme = useTheme();
  const { config, configLayer } = useOutletContext();
  const { getEdges, setEdges, screenToFlowPosition } = useReactFlow();
  const {
    mapId,
    editMode,
    entangled,
    lineType,
    mapRef,
    itensData = null,
    capacidade = 1,
    animation = "desativada",
    showLabelEnlace,
    easyMapsConfig,
  } = data;
  const [colors, setColors] = useState("");
  const [variant, setVariant] = useState("traffic");
  const edges = getEdges();
  const positionHandlers = data?.positionHandlers ?? [];
  const edgeSegmentsCount = positionHandlers.length + 1;
  const trafficReference =
    itensData?.trafego?.rx > itensData?.trafego?.tx
      ? itensData?.trafego?.rx
      : itensData?.trafego?.tx / 1000000000 ?? 0;

  const powerReference =
    itensData?.potencia?.rx < itensData?.potencia?.tx
      ? itensData?.potencia?.rx
      : itensData?.potencia?.tx;

  const percentilTraffic =
    capacidade && capacidade != 0 && trafficReference
      ? (parseFloat(trafficReference) / capacidade) * 100
      : null;

  const [isDragging, setIsDragging] = useState(false);
  let pathFunction;

  switch (lineType) {
    case "reta":
      pathFunction = getStraightPath;
      break;
    case "ortogonal":
      pathFunction = getSmoothStepPath;
      break;
    case "curvada":
      pathFunction = getBezierPath;
      break;
    default:
      pathFunction = getStraightPath;
  }

  const edgeSegmentsArray = Array.from({ length: edgeSegmentsCount }).map(
    (_, i) => {
      const segmentSource =
        i === 0 ? { x: sourceX, y: sourceY } : positionHandlers[i - 1];
      const segmentTarget =
        i === edgeSegmentsCount - 1
          ? { x: targetX, y: targetY }
          : positionHandlers[i];

      const { x: segmentSourceX, y: segmentSourceY } = segmentSource;
      const { x: segmentTargetX, y: segmentTargetY } = segmentTarget;

      const difX = targetX - sourceX;
      let _sourcePosition =
        i === 0 ? sourcePosition : difX < 0 ? "left" : "right";
      let _targetPosition =
        i === edgeSegmentsCount - 1
          ? targetPosition
          : difX < 0
          ? "right"
          : "left";

      const [edgePath, labelX, labelY] = pathFunction({
        sourceX: segmentSourceX,
        sourceY: segmentSourceY,
        sourcePosition: _sourcePosition,
        //sourcePosition,
        targetX: segmentTargetX,
        targetY: segmentTargetY,
        targetPosition: _targetPosition,
        //targetPosition,
      });

      return { edgePath, labelX, labelY };
    }
  );

  const styles = {
    clickableBaseEdge: {
      stroke: selected
        ? theme.palette.color.zabbixSeverty.selectedTranslucid
        : editMode
        ? "#5e5e5e"
        : colors,
      opacity: entangled ? 1 : 0.33,
      strokeWidth: 3,
      animation: easyMapsConfig?.animacao?.linha != false ? null : "none",
      strokeDasharray:
        editMode || animation === "desativada"
          ? "0"
          : animation === "pontilhada"
          ? "3,8"
          : "5",
    },
    divEdge: {
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      fontSize: 12,
      pointerEvents: "all",
    },
    buttonDraggable: {
      width: "20px",
      height: "20px",
      fontSize: "14px",
      borderRadius: "50%",
      cursor: "pointer",
      lineHeight: 4,
      padding: 0,
      background: theme.palette.color.zabbixSeverty.selectedTranslucid,
    },
    textLabel: {
      padding: "0px",
      m: "0px",
      width: "100%",

      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      position: "absolute",
      // transform: `translate(-50%, -50%) translate(${sourceX + targetX / 6}px,${
      //   (sourceY + targetY) / 2
      // }px)`,
      background: theme.palette.color.zabbixSeverty.default,
      padding: "5px 10px",
      display: "flex",
      flexDirection: "column",
      flexGap: "5px",
      minWidth: "150px",
      borderRadius: 5,
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
      opacity: entangled ? 1 : 0.33,
    },
    edit: {
      position: "absolute",
      transform: `translate(-50%, -50%) translate(${
        (sourceX + targetX) / 2
      }px,${(sourceY + targetY) / 2}px)`,
      background: theme.palette.color.zabbixSeverty.selectedTranslucid,
      paddingLeft: 1,
      paddingRight: 1,
      borderRadius: 5,
      fontSize: 10,
      fontWeight: 500,
      color: "#FFFFFF",
      opacity: entangled ? 1 : 0.33,
    },
  };

  useEffect(() => {
    if (
      percentilTraffic == null ||
      percentilTraffic == 0 ||
      percentilTraffic === Infinity ||
      powerReference == null ||
      powerReference === Infinity ||
      !config
    ) {
      setColors(theme.palette.enlaces.offline.main);
      return;
    }
    const coresMap = {
      trafego: config.escalas.trafego,
      potencia: config.escalas.potencia,
      ftth: config.escalas.ftth,
    };
    let cores = [];
    if (coresMap[configLayer?.[mapId]]) {
      cores = coresMap[configLayer?.[mapId]];
      let result;
      if (configLayer?.[mapId] == "trafego") {
        result = cores
          .filter((scale) => parseFloat(scale?.label) <= percentilTraffic)
          .pop();
      } else if (configLayer?.[mapId] == "potencia") {
        result = cores
          .filter((scale) => parseFloat(scale?.label) >= powerReference)
          .pop();
      }

      setColors(result.cor);
    } else setColors(theme.palette.enlaces.offline.main);
  }, [powerReference, percentilTraffic, config, variant, configLayer]);

  const handleMouseMove = (event, id, handlerIndex) => {
    let activeEdge = parseInt(event.target.dataset.active ?? -1);
    if (activeEdge === -1) {
      return;
    }
    if (activeEdge === 1) {
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const edgeIndex = edges.findIndex((edge) => edge.id === id);
      let latlng = mapRef?.containerPointToLatLng([position.x, position.y]);
      setEdges((edges) => {
        //edges[edgeIndex].id = Math.random();
        edges[edgeIndex].data.positionHandlers[handlerIndex] = mapRef
          ? {
              x: position.x,
              y: position.y,
              active: 1,
              location: {
                long: latlng.lng,
                lat: latlng.lat,
              },
            }
          : {
              x: position.x,
              y: position.y,
              active: 1,
            };
        return edges;
      });
    }
  };
  const handleMouseUp = () => {
    if (editMode) {
      setIsDragging(false);
      setEdges((edges) => {
        const updatedEdges = edges.map((edge) => ({
          ...edge,
          data: {
            ...edge.data,
            positionHandlers: edge.data.positionHandlers?.map((handler) => ({
              ...handler,
              active: -1,
            })),
          },
        }));

        return updatedEdges;
      });
    }
    return;
  };

  const handleMouseDown = (event, id, handlerIndex, active) => {
    if (editMode) {
      setIsDragging(true);
      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers[handlerIndex].active = 1;
        return edges;
      });
      event.preventDefault();
    }
    event.preventDefault();
    return;
  };

  const handleDeleteEdge = (event, id, handlerIndex) => {
    event.preventDefault();
    if (editMode) {
      //setEdges((edges) => edges.filter((edge) => edge.id !== id));
      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers?.splice(handlerIndex, 1);
        return edges;
      });
    }
    return;
  };
  const transform = (handlerIndex, x, y) => {
    return handlerIndex === 0
      ? `translate(-50%, -50%) translate(${(x + sourceX) / 2}px,${
          (y + sourceY) / 2
        }px)`
      : handlerIndex === positionHandlers.length - 1
      ? `translate(-50%, -50%) translate(${(x + targetX) / 2}px,${
          (y + targetY) / 2
        }px)`
      : null;
  };
  const handleCreateEdge = (event, id, index) => {
    if (selected && editMode) {
      //event.preventDefault();
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      let latlng = mapRef?.containerPointToLatLng([position.x, position.y]);

      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers =
          edges[edgeIndex].data.positionHandlers ?? [];
        edges[edgeIndex].data.positionHandlers.splice(
          index,
          0,
          mapRef
            ? {
                x: position.x,
                y: position.y,
                active: -1,
                location: {
                  long: latlng.lng,
                  lat: latlng.lat,
                },
              }
            : {
                x: position.x,
                y: position.y,
                active: -1,
              }
        );
        return edges;
      });
    }
  };

  let positionLabel = 0;

  if (Array.isArray(positionHandlers) && Array.isArray(edgeSegmentsArray)) {
    const posLegnth = positionHandlers.length;
    const edsLegnth = edgeSegmentsArray.length;

    positionLabel =
      posLegnth % 2 !== 0
        ? positionHandlers[(posLegnth - 1) / 2]
        : {
            x: edgeSegmentsArray[(edsLegnth - 1) / 2].labelX,
            y: edgeSegmentsArray[(edsLegnth - 1) / 2].labelY,
          };
  } else {
    return;
  }
  const convertInputTraffic = convertBitsToBytes(itensData?.trafego?.rx);
  const convertOutputTraffic = convertBitsToBytes(itensData?.trafego?.tx);

  const renderLabel = (
    positionLabel,
    itensData,
    convertInputTraffic,
    convertOutputTraffic
  ) => (
    <div
      className="nodrag nopan"
      style={{
        ...styles.label,
        zIndex: 1,
        transform: `translate(-50%, -50%) translate(${positionLabel?.x}px,${positionLabel?.y}px)`,
      }}
    >
      <span
        style={{
          ...styles.textLabel,
          ...{ minHeight: "25px", justifyContent: "center" },
        }}
      >
        {itensData?.interface || "--"}
      </span>

      {configLayer?.[mapId] == "trafego" && (
        <>
          <span style={styles.textLabel}>
            <span>{"TX:"}</span>
            {convertOutputTraffic.valor + " " + convertOutputTraffic.unidade}
          </span>
          <span style={styles.textLabel}>
            <span>{"RX:"}</span>
            {convertInputTraffic.valor + " " + convertInputTraffic.unidade}
          </span>
        </>
      )}
      {configLayer?.[mapId] == "potencia" && (
        <>
          <span style={styles.textLabel}>
            <span>{"TX:"}</span>
            {(itensData?.potencia?.tx || "-") + " dBm"}
          </span>
          <span style={styles.textLabel}>
            <span>{"RX:"}</span>
            {(itensData?.potencia?.rx || "-") + " dBm"}
          </span>
        </>
      )}
    </div>
  );

  return (
    <>
      {edgeSegmentsArray.map(({ edgePath, labelX, labelY }, index) => (
        <ClickableBaseEdge
          onDoubleClick={(event) => handleCreateEdge(event, id, index)}
          key={`edge${id}_segment${index}`}
          path={edgePath}
          markerEnd={
            index === edgeSegmentsArray.length - 1 ? markerEnd : "false"
          }
          style={styles.clickableBaseEdge}
        />
      ))}

      {positionHandlers.length === 0 && (
        <EdgeLabelRenderer key={`edge`}>
          {!editMode &&
            showLabelEnlace &&
            renderLabel(
              positionLabel,
              itensData,
              convertInputTraffic,
              convertOutputTraffic
            )}
        </EdgeLabelRenderer>
      )}

      {positionHandlers.map(({ x, y, active }, handlerIndex) => (
        <EdgeLabelRenderer key={`edge${id}_handler${handlerIndex}`}>
          {!editMode &&
            showLabelEnlace &&
            renderLabel(
              positionLabel,
              itensData,
              convertInputTraffic,
              convertOutputTraffic
            )}

          {editMode && selected && (
            <div
              data-active={active ?? -1}
              style={{
                ...styles.divEdge,
                width: active === 1 ? "1200px" : "20px",
                height: active === 1 ? "1200px" : "20px",
                transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
              }}
              className="nodrag nopan"
              onMouseMove={(event) => handleMouseMove(event, id, handlerIndex)}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <button
                data-active={active ?? -1}
                style={{
                  ...styles.buttonDraggable,
                  border:
                    active === 1 ? "1px solid #FFFFFF" : "1px solid #33333300",
                }}
                className="edgebutton"
                onMouseDown={(event) =>
                  handleMouseDown(event, id, handlerIndex, active)
                }
                onContextMenu={(event) =>
                  handleDeleteEdge(event, id, handlerIndex)
                }
              ></button>
            </div>
          )}
        </EdgeLabelRenderer>
      ))}
    </>
  );
}
