export function convertBitsToBytes(prevValue) {
  //console.log("prevValue", prevValue);
  if (!prevValue || prevValue == "-") {
    return {
      valor: `--`,
      unidade: "",
    };
  }
  let valor = parseFloat(
    typeof prevValue === "string"
      ? prevValue.replace(" bits")
      : parseFloat(prevValue)
  );
  let prefixo = "bps";
  let sufixos = ["", "k", "M", "G", "T", "P", "E"];
  let index = 0;
  valor = Math.abs(valor);
  while (valor >= 1000 && index < sufixos.length - 1) {
    valor /= 1000;
    index++;
  }
  return {
    valor: `${valor.toFixed(2)}`,
    unidade: `${sufixos[index] + prefixo}`,
  };
}

export function convertBitsToBytesUniversal(prevValue, uni) {
  //console.log("prevValue", prevValue);
  if (!prevValue && prevValue != 0) {
    return {
      valor: `--`,
      unidade: "",
    };
  }
  let valor = parseFloat(
    typeof prevValue === "string"
      ? prevValue.replace(" bits")
      : parseFloat(prevValue)
  );
  let prefixo = uni;
  let sufixos = ["", "k", "M", "G", "T", "P", "E"];
  let index = 0;
  valor = Math.abs(valor);
  while (valor >= 1000 && index < sufixos.length - 1) {
    valor /= 1000;
    index++;
  }
  return {
    valor: `${valor.toFixed(2)}`,
    unidade: `${sufixos[index] + prefixo}`,
  };
}
