import {
  alpha,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export default function LoadingUpdate({ elementSelected, visible = false }) {
  const theme = useTheme();
  const styles = {
    container: {
      position: "absolute",
      zIndex: 1000,
      top: 10,
      right: elementSelected ? 370 : 10,
      backgroundColor: theme.palette.background.default,
      padding: "10px 15px",
      borderRadius: "4px",
      border: "1px solid" + theme.palette.color.appBarBorder,
    },
  };
  return (
    visible && (
      <Stack
        sx={styles.container}
        direction={"row"}
        alignItems={"center"}
        gap={2}
      >
        <CircularProgress size="25px" />
        <Typography>Sincronizando...</Typography>
      </Stack>
    )
  );
}
