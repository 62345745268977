import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Checkbox,
  Link,
  Snackbar,
  Alert,
  Avatar,
  Icon,
  useTheme,
  Grid2,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import background from "../../assets/headerHome.jpeg";
import logoMw from "../../assets/logo4.png";
import logoMw2 from "../../assets/logo5.png";
import ImageButton from "../../componentes/imageButton";
import {
  maskCnpj,
  maskCPF,
  maskPhone,
  removeMask,
} from "../../utils/maskFields";

import { validarCpf } from "../../utils/validateCpf";
import { validarCnpj } from "../../utils/validateCnpj";

const maskCellPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
// const maskCnpj = (value) => {
//   return value
//     .replace(/\D/g, "")
//     .replace(/(\d{2})(\d)/, "$1.$2")
//     .replace(/(\d{3})(\d)/, "$1.$2")
//     .replace(/(\d{3})(\d)/, "$1/$2")
//     .replace(/(\d{4})(\d)/, "$1-$2")
//     .replace(/(-\d{2})\d+?$/, "$1");
// };
// const isValidCPF = (cpf) => {
//   if (
//     typeof cpf !== "string" ||
//     cpf.length !== 11 ||
//     !Array.from(cpf).filter((e) => e !== cpf[0]).length
//   ) {
//     return false;
//   }
//   let soma = 0;
//   let resto;
//   for (let i = 1; i <= 9; i++)
//     soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
//   resto = (soma * 10) % 11;
//   if (resto === 10 || resto === 11) resto = 0;
//   if (resto !== parseInt(cpf.substring(9, 10))) return false;
//   soma = 0;
//   for (let i = 1; i <= 10; i++)
//     soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
//   resto = (soma * 10) % 11;
//   if (resto === 10 || resto === 11) resto = 0;
//   if (resto !== parseInt(cpf.substring(10, 11))) return false;
//   return true;
// };
const hasNumbersAndChars = (value) => {
  const hasNumber = /\d/.test(value); // Verifica se há pelo menos um número.
  const hasChar = /[a-zA-Z]/.test(value); // Verifica se há pelo menos um caractere (letra).
  return hasNumber && hasChar;
};

export default function Cadastro(props) {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [checkSenha, setCheckSenha] = useState(true);
  const [checkCaracterSenha, setCheckCaracterSenha] = useState(true);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [cpf, setCpf] = useState("");
  const [inputCpf, setInputCpf] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inputCnpj, setInputCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [inputTelefone, setInputTelefone] = useState("");
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const [usuarioClienteId, setUsuarioClienteId] = useState("");
  const [senha, setSenha] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(null);
  const [pageValue, setPageValue] = useState(null);
  const [alertSize, setAlertSize] = useState(false);
  const [termosUso, setTermosUso] = useState(false);
  const [termosContrato, setTermosContrato] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorCpf, setShowErrorCpf] = useState(false);
  const [showErrorCnpj, setShowErrorCnpj] = useState(false);
  const [checkData, setCheckData] = useState(false);
  const [checkCadastro, setCheckCadastro] = useState(true);
  const [logsLoginClienteId, setLogsLoginClienteId] = useState("");
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  // const [accountType, setAccountType] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const logo = `${window?.config?.REACT_APP_LOGO_SMALL_URL || logoMw}`;
  const logoLight = `${window?.config?.REACT_APP_LOGO_SMALL_URL || logoMw2}`;
  const isPartner = window?.config?.REACT_APP_IS_PARTNER === "true" || false;
  const backgroundImage = `${
    window?.config?.REACT_APP_BACKGROUND_IMAGE_URL || background
  }`;

  const token = () => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      return result.token;
    } else {
      return "";
    }
  };
  const handleVoltar = () => {
    navigate("/accountType");
  };
  useEffect(() => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    setUsuarioClienteId(result?.refreshToken?.usuarioClienteId);

    let pageValue = localStorage.getItem("pageValue");
    pageValue = parseInt(pageValue);
    setPageValue(pageValue);
    setPessoaFisica(Boolean(!pageValue));

    let resultPerfil = localStorage.getItem("perfil");
    if (resultPerfil) {
      resultPerfil = JSON.parse(resultPerfil);
      if (resultPerfil.status) {
        navigate("/homein");
      }
    }
    let resultLogLogin = localStorage.getItem("logs");
    if (resultLogLogin !== "undefined") {
      resultLogLogin = JSON.parse(resultLogLogin);
      setLogsLoginClienteId(resultLogLogin?.id);
    }
    let resultAcesso = localStorage.getItem("acesso");
    if (resultAcesso !== "undefined" && resultAcesso) {
      resultAcesso = JSON.parse(resultAcesso);
      setAcessoClientesId(resultAcesso.acessoClientesId);
    }
  }, []);
  useEffect(() => {
    if (confirmarSenha === senha) {
      setCheckSenha(false);
    } else {
      setCheckSenha(true);
    }
    if (senha.length >= 6 && hasNumbersAndChars(senha)) {
      setCheckCaracterSenha(false);
    } else {
      setCheckCaracterSenha(true);
    }
  }, [senha, confirmarSenha]);

  useEffect(() => {
    if (pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nome !== "" &&
        sobrenome !== "" &&
        dataDeNascimento !== null &&
        cpf !== "" &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        setCheckCadastro(false);
      } else {
        setCheckCadastro(true);
      }
    }

    if (!pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nomeFantasia !== "" &&
        cnpj !== "" &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        setCheckCadastro(false);
      } else {
        setCheckCadastro(true);
      }
    }
  }, [
    senha,
    nome,
    sobrenome,
    confirmarSenha,
    cpf,
    cnpj,
    razaoSocial,
    nomeFantasia,
    dataDeNascimento,
    telefone,
    pessoaFisica,
    termosContrato,
    termosUso,
  ]);

  const verificaCampos = () => {
    if (pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nome !== "" &&
        sobrenome !== "" &&
        dataDeNascimento !== null &&
        cpf !== "" &&
        validarCpf(cpf) &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (!pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nomeFantasia !== "" &&
        cnpj !== "" &&
        validarCnpj(cnpj) &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  //funcao para limpar todos os valores do formularioo
  const clearCampos = () => {
    setNome("");
    setSobrenome("");
    setCpf("");
    setDataDeNascimento(null);
    setSenha("");
    setConfirmarSenha("");
    setRazaoSocial("");
    setNomeFantasia("");
    setCnpj("");
    setTelefone("");
    setInputCnpj("");
    setInputCpf("");
    setInputTelefone("");
    setShowErrorCpf(false);
    setShowErrorCnpj(false);
    setShowError(false);
  };
  async function handleApi() {
    try {
      setIsSubmit(true);
      if (!verificaCampos()) return;

      const response = await api.post("/perfil/create", {
        usuarioClienteId,
        foto,
        nome,
        sobrenome,
        cpf,
        cnpj,
        telefone,
        dataDeNascimento,
        nomeFantasia,
        razaoSocial,
        pessoaFisica,
        status: true,
        novaSenha: senha,
        acessoClientesId,
        logsLoginClienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        localStorage.setItem(
          "perfil",
          JSON.stringify(response.data.perfilCliente)
        );
        let json1 = JSON.parse(localStorage.getItem("1"));
        json1["perfilAlreadyExists"] = true;
        localStorage.setItem("1", JSON.stringify(json1));
        navigate("/homein");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  const styles = {
    LoadingLogo: {
      position: "relative",
      margin: "50px",
    },
    CircularProgress: {
      color: "primary",
      position: "absolute",
      top: -9,
      left: -6,
      zIndex: 1,
    },
    ContainerBox: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperPhoto: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "40vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", lg: "flex" },
      justifyContent: "center",
      alignContent: "center",
      objectFit: "cover",
    },
    boxTitlePhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    title: {
      fontSize: { lg: "3em", md: "2em" },
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
      maxWidth: "max-content",
      textWrap: "wrap",
    },
    subTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    boxHeaderAndForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      gap: 6,
      padding: 4,
      width: { xs: "100vw", lg: "60vw" },
      minWidth: "330px",
      height: "100vh",
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: 2,
      minWidth: "330px",
    },
    icon: { height: "auto", width: "auto" },
    img: { height: "auto", width: "100px" },
    stackForm: {
      width: "100%",
      maxWidth: "520px",
      height: "auto",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    boxForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: 1.5,
    },
    boxFormCode: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      gap: 2,
      width: { xs: "80%", md: "100%" },
      minWidth: "300px",
    },
    boxTitles: { paddingBottom: 2, display: "flex", flexDirection: "column" },
    boxFields: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    },
    boxButtonAndLinks: {
      display: "flex",
      height: "max-content",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "stretch",
      mt: 2,
    },
    textField: {
      width: "100%",
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      marginTop: "25px",
      height: "40px",
      marginBottom: "20px",
      fontSize: 16,
      textTransform: "none",
    },
    titleForm: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "5px",
      alignSelf: "stretch",
    },
    formCodeContent: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      //alignSelf: "strminWidth: "320px",etch",
      width: "100%",
    },
    codeErrorMsg: {
      fontSize: "13px",
      marginTop: "-10px",
      color: "#e83750",
      marginBottom: "-20px",
    },
    imageButton: {
      width: "40px",
      height: "40px",
      color: "white",
      backgroundColor: "primary.main",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      position: "absolute",
      margin: "50px 0px 0px 65px",
      "&:hover": {
        backgroundColor: "background.textField",
        color: "primary.main",
      },
    },
    boxTerms: {
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    avatar: {
      width: "100px",
      height: "100px",
      outline: "8px solid" + " " + "background.paper",
    },
  };
  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.boxHeaderAndForm}>
          <Box sx={styles.boxHeader}>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              color="primary.main"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                clearCampos();
                handleVoltar();
              }}
            >
              Voltar
            </Button>

            <Icon style={styles.icon}>
              <img
                alt=""
                src={colorMode === "light" ? logo : logoLight}
                style={styles.img}
              />
            </Icon>
          </Box>
          <Stack sx={styles.stackForm}>
            <Box sx={styles.boxForm}>
              <Box sx={styles.boxTitles}>
                <Typography
                  fontSize="1.3em"
                  fontWeight={400}
                  color="text.primary"
                >
                  Gerenciar novo acesso
                </Typography>
              </Box>
              <Box sx={styles.boxFields}>
                {pageValue === 0 && (
                  <Grid2 container columnSpacing={1.5} rowSpacing={2}>
                    <Grid2 item size={{ xs: 12, sm: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          src={foto}
                          color="secondary"
                          sx={styles.avatar}
                        />
                        <ImageButton
                          setFoto={setFoto}
                          sx={styles.imageButton}
                        />
                      </Box>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 9 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          with: "100%",
                          gap: 2,
                        }}
                      >
                        <TextField
                          required
                          id="nome"
                          placeholder="Nome"
                          value={nome}
                          fullWidth
                          sx={styles.textField}
                          onChange={(event) => {
                            setNome(event.target.value);
                          }}
                          error={isSubmit && !nome}
                          helperText={isSubmit && !nome && "Campo inválido"}
                        />
                        <TextField
                          required
                          id="sobrenome"
                          placeholder="Sobrenome"
                          value={sobrenome}
                          fullWidth
                          sx={styles.textField}
                          onChange={(event) => {
                            setSobrenome(event.target.value);
                          }}
                          error={isSubmit && !sobrenome}
                          helperText={
                            isSubmit && !sobrenome && "Campo inválido"
                          }
                        />
                      </Box>
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        id="telefone"
                        placeholder="Telefone"
                        value={inputTelefone}
                        sx={styles.textField}
                        onChange={(event) => {
                          const value = event.target.value;
                          setTelefone(removeMask(value));
                          if (value.length <= 14) {
                            setInputTelefone(maskCellPhone(value));
                          } else {
                            setInputTelefone(maskPhone(value));
                          }
                        }}
                        error={isSubmit && !telefone}
                        helperText={isSubmit && !telefone && "Campo inválido"}
                      />
                    </Grid2>

                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                      <TextField
                        required
                        id="cpf"
                        placeholder="CPF"
                        value={inputCpf}
                        sx={styles.textField}
                        onChange={(event) => {
                          const value = event.target.value;
                          setCpf(removeMask(maskCPF(value)));
                          setInputCpf(maskCPF(value));
                        }}
                        error={isSubmit && !validarCpf(cpf)}
                        helperText={
                          isSubmit && !validarCpf(cpf) && "CPF inválido"
                        }
                      />
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"pt-br"}
                      >
                        <DatePicker
                          disableFuture
                          value={dataDeNascimento}
                          maxDate={dayjs()}
                          onChange={(newValeu) => {
                            setDataDeNascimento(dayjs(new Date(newValeu)));
                          }}
                          sx={styles.textField}
                          error={isSubmit && !dataDeNascimento}
                          helperText={
                            isSubmit && !dataDeNascimento && "Campo inválido"
                          }
                        />
                      </LocalizationProvider>
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        sx={styles.textField}
                        name="senha"
                        placeholder="Senha"
                        color="primary"
                        helperText={
                          isSubmit &&
                          checkCaracterSenha &&
                          "As senhas devem ter no mínimo 6 caracteres e conter pelo menos uma letra ou um número."
                        }
                        error={isSubmit && checkCaracterSenha}
                        type={showPass ? "text" : "password"}
                        value={senha}
                        onChange={(event) => {
                          setSenha(event.target.value);
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() => setShowPass((event) => !event)}
                                >
                                  {showPass ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        error={checkSenha && isSubmit}
                        sx={styles.textField}
                        name="confirmarsenha"
                        placeholder="Confirmar nova senha"
                        color="primary"
                        helperText={
                          checkSenha &&
                          isSubmit &&
                          "Essas senhas não correspondem"
                        }
                        type={showPassConf ? "text" : "password"}
                        value={confirmarSenha}
                        onChange={(event) => {
                          setConfirmarSenha(event.target.value);
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    setShowPassConf((event) => !event)
                                  }
                                >
                                  {showPassConf ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid2>
                  </Grid2>
                )}
                {pageValue === 1 && (
                  <Grid2 container columnSpacing={1.5} rowSpacing={2}>
                    <Grid2 item size={{ xs: 12, md: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          src={foto}
                          color="secondary"
                          sx={{
                            width: "100px",
                            height: "100px",
                            outline: "8px solid" + " " + "background.paper",
                          }}
                        />
                        <ImageButton
                          setFoto={setFoto}
                          sx={styles.imageButton}
                        />
                      </Box>
                    </Grid2>
                    <Grid2 alignSelf="end" item size={{ xs: 12, md: 9 }}>
                      <TextField
                        required
                        id="nomeFantasia"
                        placeholder="Nome Fantasia"
                        value={nomeFantasia}
                        sx={styles.textField}
                        onChange={(event) => {
                          setNomeFantasia(event.target.value);
                        }}
                        error={isSubmit && !nomeFantasia}
                        helperText={
                          isSubmit && !nomeFantasia && "Campo inválido"
                        }
                      />{" "}
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        id="telefone"
                        placeholder="Telefone"
                        value={inputTelefone}
                        sx={styles.textField}
                        onChange={(event) => {
                          const value = event.target.value;
                          setTelefone(removeMask(value));
                          if (value.length <= 14) {
                            setInputTelefone(maskCellPhone(value));
                          } else {
                            setInputTelefone(maskPhone(value));
                          }
                        }}
                        error={isSubmit && !telefone}
                        helperText={isSubmit && !telefone && "Campo inválido"}
                      />
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        id="cnpj"
                        placeholder="CNPJ"
                        value={inputCnpj}
                        sx={styles.textField}
                        onChange={(event) => {
                          const value = event.target.value;
                          setInputCnpj(maskCnpj(value));
                          setCnpj(removeMask(value));
                        }}
                        error={isSubmit && !validarCnpj(cnpj)}
                        helperText={
                          isSubmit && !validarCnpj(cnpj) && "CNPJ inválido"
                        }
                      />
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        sx={styles.textField}
                        name="senha"
                        placeholder="Senha"
                        color="primary"
                        helperText={
                          isSubmit &&
                          checkCaracterSenha &&
                          "As senhas devem ter no mínimo 6 caracteres e conter pelo menos uma letra ou um número."
                        }
                        error={isSubmit && checkCaracterSenha}
                        type={showPass ? "text" : "password"}
                        value={senha}
                        onChange={(event) => {
                          setSenha(event.target.value);
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() => setShowPass((event) => !event)}
                                >
                                  {showPass ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid2>
                    <Grid2 item size={12}>
                      <TextField
                        required
                        error={checkSenha && isSubmit}
                        sx={styles.textField}
                        name="confirmarsenha"
                        placeholder="Confirmar nova senha"
                        color="primary"
                        helperText={
                          checkSenha &&
                          isSubmit &&
                          "Essas senhas não correspondem"
                        }
                        type={showPassConf ? "text" : "password"}
                        value={confirmarSenha}
                        onChange={(event) => {
                          setConfirmarSenha(event.target.value);
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    setShowPassConf((event) => !event)
                                  }
                                >
                                  {showPassConf ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid2>
                  </Grid2>
                )}
              </Box>
              <Box sx={styles.boxButtonAndLinks}>
                <Box sx={styles.boxTerms}>
                  <Checkbox
                    checked={termosUso}
                    onChange={(event) => {
                      setTermosUso(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ fontSize: "0.875em" }}
                  />
                  <Stack direction={"row"} gap={1}>
                    <Typography fontSize={"0.875em"}>Eu aceito os</Typography>
                    <Link href="#" underline="always" fontSize={"0.875em"}>
                      termos de uso
                    </Link>
                  </Stack>
                </Box>
                <Box sx={styles.boxTerms}>
                  <Checkbox
                    checked={termosContrato}
                    onChange={(event) => {
                      setTermosContrato(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Stack direction={"row"} gap={1}>
                    <Typography fontSize={"0.875em"}>Eu aceito os</Typography>
                    <Link href="#" underline="always" fontSize={"0.875em"}>
                      termos de contrato
                    </Link>
                  </Stack>
                </Box>

                <Button
                  variant="contained"
                  sx={styles.button}
                  //  disabled={checkCadastro}
                  onClick={() => {
                    // if (checkCaracterSenha) {
                    //   setShowError(true);
                    // } else if (!isValidCPF(cpf)) {
                    //   setShowErrorCpf(true);
                    //   if (!pessoaFisica) {
                    //     handleApi();
                    //   }
                    //   //  } else if (!isCNPJ.validate(cnpj)) {
                    //   //    setShowErrorCnpj(true);
                    // } else {
                    //   handleApi();
                    // }
                    handleApi();
                  }}
                >
                  Cadastrar
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={styles.paperPhoto}>
          {!isPartner && (
            <Box sx={styles.boxTitlePhoto}>
              <Typography sx={styles.title}>
                Monitoramento eficiente e confiável.
              </Typography>
              <br></br>
              <Typography sx={styles.subTitle}>
                Monitoramento de infraestrutura de redes para provedores de
                internet, com suporte confiável e atencioso.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>{" "}
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
