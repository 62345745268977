import {
  AppBar,
  Avatar,
  Box,
  Container,
  createSvgIcon,
  Icon,
  IconButton,
  Toolbar,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from "./notifications";
import Profile from "./profile";
import Tools from "./tools";
import { cloneElement, useEffect, useState } from "react";
import { BorderColor, MenuOpen } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
export default function TopBar(props) {
  const {
    setOpenDrawer = () => {},
    setOpenRightBar = () => {},
    openRightBar,
    iconLogo = () => {},
    clientLogo,
    link = "/",
    tools,
    socket,
    socketCliente,
    //administrador,
    colorMode,
    snackbar,
    setSnackbar = () => {},
    setColorMode = () => {},
    ocultar = false,
    backgroundColor = null,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  //const IconLogo = createSvgIcon(iconLogo);
  const [administrador, setAdministrador] = useState(false);
  useEffect(() => {
    let sidebarOpen = JSON.parse(localStorage.getItem("sidebarCollapsed"));
    if (sidebarOpen !== null) setOpenRightBar(sidebarOpen);
  }, []);

  const styles = {
    appBar: {
      // /display: "relative",
      minWidth: "350px",
      width: "100%",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      position: "sticky",
      top: 0,
      left: 0,
      right: "950",
      bgcolor: backgroundColor
        ? backgroundColor
        : theme.palette.background.paper,
      //border: "none",
      borderBottom: "1px solid " + theme.palette.color.appBarBorder,

      zIndex: 1200,
    },
    container: { padding: 0, "&.MuiContainer-root": { paddingX: 0 } },
    toolbar: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingX: 1,
    },
    boxMenuAndLogo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 1,
      //paddingX: 1,
      width: "270px",
    },
    iconButtonLogo: {
      flexDirection: "row",
      alignItems: "center",
      marginX: !ocultar ? 1 : 6,
      padding: 0,
      height: "40px",
      borderRadius: 1,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      // width: "100%",
      // height: "100%'",
      width: "180px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    imgIcon: {
      width: 170,
      height: "auto",
      //fill: "white",
      //fill: theme.palette.text.secondary,
    },
    buttonMenuDawer: {
      padding: 1,
      //color: "white",
      display: { xs: "flex", lg: "none" },
    },
    buttonMenuRightBar: {
      padding: 1,
      //color: "white",
      display: { xs: "none", lg: "flex" },
    },
    boxRight: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
  };

  const handleRightBarToggle = () => {
    setOpenRightBar((preValue) => {
      localStorage.setItem("sidebarCollapsed", JSON.stringify(!preValue));
      return !preValue;
    });
  };
  const handleDrawerToggle = () => {
    setOpenDrawer((preValue) => !preValue);
  };
  const customIconLogo = cloneElement(iconLogo, {
    style: {
      width: "180px",
      fill: theme.palette.text.primary,
    },
    //className: "custom-svg",
    role: "img",
  });
  useEffect(() => {
    const handleUsuarioId = () => {
      let result = {};
      result = localStorage.getItem("1");
      //
      if (result) {
        result = JSON.parse(result);
        if (
          result.usuarioCliente &&
          (result.usuarioCliente.codigoPermissao === "adm001" ||
            result.usuarioCliente.codigoPermissao === "adm002")
        ) {
          setAdministrador(true);
        }
      }
    };
    handleUsuarioId();
  });
  return (
    <AppBar
      position="sticky"
      sx={styles.appBar}
      elevation={0}
      variant="outlined"
    >
      <Container maxWidth="100vw" sx={styles.container}>
        <Toolbar sx={styles.toolbar} disableGutters>
          <Box sx={styles.boxMenuAndLogo}>
            {!ocultar && (
              <>
                <IconButton
                  sx={styles.buttonMenuDawer}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>

                <IconButton
                  sx={styles.buttonMenuRightBar}
                  onClick={handleRightBarToggle}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
            {clientLogo ? (
              <IconButton href={link} sx={styles.iconButtonLogo}>
                <Icon sx={styles.icon}>
                  <img src={clientLogo} style={styles.imgIcon} />
                </Icon>
              </IconButton>
            ) : (
              <IconButton href={link} sx={styles.iconButtonLogo}>
                <Icon sx={styles.icon}>{customIconLogo}</Icon>
              </IconButton>
            )}
          </Box>

          <Box sx={styles.boxRight}>
            {tools}
            <Tools />
            <Notifications
              socket={socket}
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              style={{ display: { xs: "flex", xsm: "none" } }}
            />
            <Profile
              socket={socket}
              socketCliente={socketCliente}
              colorMode={colorMode}
              setColorMode={setColorMode}
              administrador={administrador}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
