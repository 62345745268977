import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import "reactflow/dist/style.css";
import SliderBarMaps from "../../../../componentes/easyMaps/sideBarEasyMaps";
import api from "../../../../services/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import MenuMaps from "../../../../componentes/easyMaps/menuMaps";
import ReactFlowMapas from "./reactFlowMapa";
//import useUndoable from "use-undoable";
import { ReactComponent as BarIcon } from "../../../../assets/easyMaps/titlebar.svg";
import LoadingUpdate from "../../../../componentes/easyMaps/loadingUpdate";

const MapaTopologia = ({ socketCliente }) => {
  const tipo = "easymon";
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState({});
  const [elements, setElements] = useState([]);
  // const [
  //   elements,
  //   setElements,
  //   { undo, canUndo, redo, reset, canRedo, past, future },
  // ] = useUndoable([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [connectionsAnalog, setConnectionsAnalog] = useState([]);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [edgeSelected, setEdgeSelected] = useState(null);
  const [checkChanges, setCheckChanges] = useState(false);
  const [elementsConnected, setElementsConnected] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [sinc, setSinc] = useState(false);
  const [focusNodeId, setFocusNodeId] = useState(null);
  const [coordenadasFlow, setCoordenadasFlow] = useState(null);
  const [loadingMap, setLoadingMap] = useState(true);
  const [loadinButtonEnlace, setLoadinButtonEnlace] = useState(false);
  const { config, configLocal, setConfigLocal, configLayer, setConfigLayer } =
    useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const editmodeRef = useRef();

  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 1500,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
    editMode: {
      position: "absolute",
      zIndex: 1001,
      fontSize: "13px",
      border:
        (fullScreem ? "4px" : "2px") + " solid " + theme.palette.secondary.main,
      display: "flex",
      alignItems: "stop",
      justifyContent: "center",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      //boxShadow: "inset 0px 0px 10px 0px " + theme.palette.color.alerts.info,
    },
    dotEditMode: {
      mt: "-2px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "fit-content",
    },
    textEditMode: {
      mt: "-27px",
      color: "white",
      fontSize: "15px",
      width: "fit-content",
    },
  };
  useEffect(() => {
    editmodeRef.current = editMode;
    if (!editMode) {
      updateDataElementSocket();
    }
  }, [editMode]);

  useEffect(() => {
    handleApi();
  }, [id]);

  useEffect(() => {
    // atualiza as confi de layer do mapa para o default
    if (!configLayer?.[id]) {
      setConfigLayer((configLayer) => ({
        ...configLayer,
        [id]: "trafego",
      }));
    }
    let timeInterval = config?.atualizacao?.trafego || 60000;
    const interval = window.setInterval(() => {
      updateDataElementSocket();
    }, timeInterval);

    return () => window.clearInterval(interval);
  }, [id, config]);

  async function handleApi() {
    try {
      setLoadingMap(true);
      setElementSelected(null);
      setConnectionSelected(null);
      await Promise.all([
        handleApiGetProdutos(tipo),
        handleApiGetMap(id),
        handleApiGetMaps(),
        handleApiGetElements(id),
        getIconDB(),
      ]);
    } catch (error) {
    } finally {
      setLoadingMap(false);
    }
  }

  //-----funcoes de chamada a api
  async function handleApiGetElements(mapId) {
    try {
      const response = await api.post("/easyMaps/element/findMany", {
        mapId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os elementos",
          severity: "error",
        });
      } else {
        if (response.data) {
          setElements(response.data.responseAlerts);
          //reset(response.data.responseAlerts);
          setConnections(
            response.data.connections ? [...response.data.connections] : []
          );
          setConnectionsAnalog(
            response.data.connections ? [...response.data.connections] : []
          );
          await updateDataElementSocket();
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingMap(false);
    }
  }
  async function handleApiGetMap(mapId) {
    try {
      const response = await api.post("/easyMaps/map/findMapById", {
        mapId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/pages/notFound");
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      //setLoadingProdutos(false);
    }
  }
  const searchItem = (itens, tipo) => {
    const item = itens.find((item) =>
      item.key_?.toLowerCase().replace(/_/g, "").includes(tipo)
    );
    return item;
  };
  async function updateDataElementSocket() {
    if (editmodeRef.current) return;
    try {
      setSinc(true);
      socketCliente.emit("getDataElementsMap", { mapId: id }, (response) => {
        setSinc(false);
        if (response.status !== 200) {
          setSnackbar({
            children: "Error: Não foi possível atualizar os elemntos",
            severity: "error",
          });
        } else {
          let responseData = response?.data;

          //Atualiza os elementos

          setElements((elements) => {
            elements.map((ele) => {
              let elemntoUpdate = responseData?.find(
                (eleUpdate) => eleUpdate?.elementId == ele?.id
              );

              //atualiza os alertas
              ele.alerts = elemntoUpdate?.alerts;
              //adicionar ping, snmp e latẽncia em data
              if (!ele?.data) ele["data"] = {};

              let itens = elemntoUpdate?.itensHosts || [];

              ele.data["itens"] = itens?.filter(
                (con) =>
                  con?.key_?.toLowerCase() != "icmppingloss" &&
                  con?.key_?.toLowerCase() != "snmp" &&
                  con?.key_?.toLowerCase() != "icmppingsec" &&
                  con?.key_?.toLowerCase() != "icmpping"
              );

              ele.data["perdaPing"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingloss"
              );

              ele.data["snmp"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "snmp"
              );

              ele.data["latencia"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingsec"
              );
              ele.data["ping"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmpping"
              );
            });
            return [...elements];
          });

          // Atualiza as conexões

          let connectionsUpdate = responseData.flatMap(
            (ele) => ele.connections
          );

          setConnections((connections) => {
            return [
              ...connections.map((conn) => {
                let connectionUpdate = connectionsUpdate.find(
                  (connUp) => connUp.id == conn.id
                );
                if (!connectionUpdate) return conn;
                if (!conn.data) conn["data"] = {};

                // obtem a interface da conexão
                conn.data["interface"] = findSelectedInterface(conn?.item);

                // --- adiciona as informações de tráfego em data ---
                let valueTrafficRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "inoctets")?.lastvalue
                );

                let valueTrafficTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "outoctets")?.lastvalue
                );

                conn.data["trafego"] = {
                  rx: isNaN(valueTrafficRx) ? "-" : +valueTrafficRx.toFixed(2),

                  tx: isNaN(valueTrafficTx) ? "-" : +valueTrafficTx.toFixed(2),

                  units: valueTrafficTx?.units || null,
                };

                // --- adiciona as informações de potência em data ---
                let valuePowerRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "rxpower")?.lastvalue
                );
                let valuePowerTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "txpower")?.lastvalue
                );
                conn.data["potencia"] = {
                  rx: isNaN(valuePowerRx) ? "-" : +valuePowerRx.toFixed(2),

                  tx: isNaN(valuePowerTx) ? "-" : +valuePowerTx.toFixed(2),

                  unit: valuePowerTx?.units || null,
                };

                // --- adiciona as demais informações em data ---

                conn.data["status"] = connectionUpdate?.status;
                conn.data["itens"] =
                  connectionUpdate?.itens
                    ?.filter(
                      (con) =>
                        !con.key_?.toLowerCase()?.includes("inoctets") &&
                        !con.key_?.toLowerCase()?.includes("outoctets") &&
                        !con.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("rxpower") &&
                        !con.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("txpower") &&
                        !con.key_?.toLowerCase()?.includes("operstatus")
                    )
                    ?.map((item) => ({
                      name: item.name,
                      lastValue: item.lastvalue,
                      units: item.units,
                    })) || [];

                return conn;
              }),
            ];
          });
        }
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar",
        severity: "error",
      });
    }
  }
  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapas(
            response.data.map((map) => ({
              nome: map.titulo,
              id: map.id,
              tipo: map.tipo,
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetProdutos(tipo) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/cliente/produto/findMany", {
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      //setLoadingProdutos(false);
    }
  }
  const extractInterfaceName = (key) =>
    key?.match(/\[([^\],]+?(?=\s\-|,|\]))/)?.[1];
  const findSelectedInterface = (items) => {
    const selectedItem = items?.find((item) => {
      const key = item.key_?.toLowerCase();
      return (
        key?.includes("inoctets") ||
        key?.replace(/_/g, "").includes("rxpower") ||
        key?.includes("outoctets") ||
        key?.replace(/_/g, "").includes("txpower")
      );
    });
    if (!selectedItem) return "";
    return extractInterfaceName(selectedItem?.key_);
  };

  async function getIconDB() {
    try {
      const response = await api.get("/easyMaps/img/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      // setHostLoading(false);
    }
  }
  //----funcoes do socket
  async function handleCreateLink(
    connection,
    edgeSelected,
    line,
    capacidade,
    animation,
    hostOriginId
  ) {
    try {
      //return;
      setLoadinButtonEnlace(true);
      let createConnection = connection;
      createConnection.positionHandlers =
        edgeSelected?.data?.positionHandlers?.map((value) => {
          return { ...value, active: -1 };
        }) ?? [];
      createConnection.config = {
        type: line ?? "reta",
        sourceHandle: edgeSelected?.sourceHandle ?? "right",
        targetHandle: edgeSelected?.targetHandle ?? "left",
        capacidade,
        animation,
        hostOriginId: hostOriginId || null,
      };
      //return;
      socketCliente.emit("createLink", createConnection, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data?.link) {
            let updateConnection = response.data.link;
            setConnections((connections) => {
              return connections.map((con) =>
                con.id === updateConnection.id ? { ...updateConnection } : con
              );
            });

            setConnections((connections) => [
              response.data?.link,
              ...connections,
            ]);
            //setConnectionSelected(response.data?.link);
            setConnectionSelected(null);
            setEdgeSelected(null);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    } finally {
      setLoadinButtonEnlace(false);
      setCheckChanges(false);
    }
  }
  const handleUpdateLink = async (
    connection,
    edgeSelected,
    line,
    capacidade,
    animation,
    hostOriginId
  ) => {
    try {
      setLoadinButtonEnlace(true);
      let updateConnection = connection;
      updateConnection.positionHandlers =
        edgeSelected?.data?.positionHandlers?.map((value) => {
          return { ...value, active: -1 };
        }) ?? [];
      updateConnection.config = {
        type: line ?? "reta",
        sourceHandle: edgeSelected?.sourceHandle ?? "right",
        targetHandle: edgeSelected?.targetHandle ?? "left",
        capacidade,
        animation,
        hostOriginId: hostOriginId || null,
      };
      socketCliente.emit("updateLink", updateConnection, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            setSnackbar({
              children: "Link atualizado com sucesso!",
              severity: "success",
            });
            let updateConnection = response.data.link;
            setConnections((connections) => {
              return connections.map((con) =>
                con.id === updateConnection.id ? { ...updateConnection } : con
              );
            });
            // setConnectionSelected(null);
            // setElementSelected(null);
            // updateDataElementSocket();
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    } finally {
      setLoadinButtonEnlace(false);
      setCheckChanges(false);
    }
  };

  const handleDeleteLink = async (id) => {
    try {
      socketCliente.emit("deleteLink", { id }, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          setElementSelected(null);
          if (response.data) {
            let updateConnection = response.data.link;
            setConnections((cons) =>
              cons.filter(({ id }) => id !== updateConnection?.id)
            );
            setConnectionSelected(null);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    }
  };
  //para caso de duplicar elemento
  const handleCreateElement = async (node) => {
    try {
      let data = { ...elements.find(({ id }) => id === node.id) };
      data.titulo = data?.titulo + " (cópia)";
      data.configuracao = {
        ...data.elementConfig?.config,
        coordenadas: {
          long: data.elementConfig.config?.coordenadas.long + 50,
          lat: data.elementConfig.config?.coordenadas.lat + 50,
        },
      };
      socketCliente.emit("createElement", data, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            setElements((elements) => [response.data.element, ...elements]);
            setElementSelected(response.data?.element);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    } finally {
      setCheckChanges(false);
    }
  };
  const handleDeleteElement = async (id) => {
    try {
      socketCliente.emit("deleteElement", { id }, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            setConnections((cons) =>
              cons.filter(
                (con) =>
                  con.referenceId !== response.data?.element?.id &&
                  con.destinationId !== response.data?.element?.id
              )
            );
            setElements((eles) =>
              eles.filter(({ id }) => response.data?.element?.id !== id)
            );
            setElementSelected(null);
            setConnectionSelected(null);
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    } finally {
      setCheckChanges(false);
    }
  };
  const handleUpdateElement = async (element, node) => {
    let data = element;
    data.configuracao = data?.elementConfig?.config;
    data.configuracao.coordenadas = {
      long: node?.position?.x,
      lat: node?.position?.y,
    };
    data.connections = {
      updates: true
        ? []
        : connections.filter(
            ({ referenceId, destinationId }) =>
              data.id === referenceId || data.id === destinationId
          ),
      deletes: [],
    };
    try {
      socketCliente.emit("updateElement", data, (response) => {
        if (response.status == "Error") {
          setSnackbar({
            children: "Error: Não foi possível atualizar o elemento",
            severity: "error",
          });
        } else {
          if (response.data) {
            let updateElement = response.data.element;
            setElements((elements) => {
              return elements.map((ele) =>
                ele.id === updateElement.id
                  ? { ...updateElement, alerts: ele.alerts }
                  : ele
              );
            });
            if (elementSelected) setElementSelected({ ...updateElement });
          }
        }
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Error na comunicação via socket"
        } `,
        severity: "error",
      });
    } finally {
      setCheckChanges(false);
    }
  };

  const changeConnection = (edgeId, updateConfig, updateData) => {
    setCheckChanges(true);
    setConnectionSelected((con) => {
      return {
        ...con,
        ...updateData,
        config: {
          ...con.config,
          ...updateConfig,
        },
      };
    });
  };
  // const handleCancelLink = () => {
  //   // console.log("aquiiiiiiiiiiiiiiiiiii");
  //   // setConnectionSelected(null);
  //   // setElementSelected(null);
  //   // setCheckChanges(false);
  // };
  // const handleSelectlLink = (con) => {
  //   setConnectionSelected(con);
  //   setCheckChanges(false);
  // };

  const addMapInMaps = (map) => {
    setMapas([...mapas, { nome: map?.titulo, tipo: map?.tipo, id: map?.id }]);
  };
  return (
    <>
      <Stack direction="row">
        <SliderBarMaps
          map={map}
          iconsElements={iconsElements}
          elements={elements}
          setFocusNodeId={setFocusNodeId}
        />
        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }
              : styles.fullScreem
          }
          width="100%"
        >
          {editMode && (
            <Box sx={styles.editMode}>
              <Box sx={styles.dotEditMode}>
                <BarIcon width={"250px"} fill={theme.palette.secondary.main} />
                <Typography sx={styles.textEditMode}>Modo de Edição</Typography>
              </Box>
            </Box>
          )}
          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" />
              <Typography fontSize="14px">
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}{" "}
          <LoadingUpdate
            visible={sinc && !loadingMap}
            elementSelected={!!elementSelected}
          />
          <ReactFlowMapas
            setEasyMapsConfig={setConfigLocal}
            easyMapsConfig={configLocal}
            setIconsElements={setIconsElements}
            connections={connections}
            setEdgeSelected={setEdgeSelected}
            connectionsAnalog={connectionsAnalog}
            elements={elements}
            elementSelected={elementSelected}
            setElementSelected={setElementSelected}
            setElementsConnected={setElementsConnected}
            connectionSelected={connectionSelected}
            setConnectionSelected={setConnectionSelected}
            iconsElements={iconsElements}
            setOpenRightBar={setOpenRightBar}
            setLoadingPage={setLoadingMap}
            focusNodeId={focusNodeId}
            handleCreateElement={handleCreateElement}
            handleUpdateElement={handleUpdateElement}
            handleDeleteElement={handleDeleteElement}
            handleCreateLink={handleCreateLink}
            handleDeleteLink={handleDeleteLink}
            handleUpdateLink={handleUpdateLink}
            //handleCancelLink={handleCancelLink}
            editMode={editMode}
            setEditMode={setEditMode}
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            setSnackbar={setSnackbar}
            mapId={id}
            title={map?.titulo}
            checkChanges={checkChanges}
            setCoordenadasFlow={setCoordenadasFlow}
            setElements={setElements}
            setConnections={setConnections}
            edgeSelected={edgeSelected}
            coordenadasFlow={coordenadasFlow}
            elementsConnected={elementsConnected}
            openRightBar={openRightBar}
            setFocusNodeId={setFocusNodeId}
            socketCliente={socketCliente}
            mapas={mapas}
            addMapInMaps={addMapInMaps}
            produtos={produtos}
            changeConnection={changeConnection}
            setCheckChanges={setCheckChanges}
            updateDataElementSocket={() => {}}
            loadinButtonEnlace={loadinButtonEnlace}
          />
        </Stack>
      </Stack>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
};
export default MapaTopologia;
