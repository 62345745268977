import {
  Divider,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Alert,
  Card,
} from "@mui/material";
import { Children, cloneElement, Fragment, useEffect, useState } from "react";
import StatusCard from "../statusCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StatusPotencia from "../../statusPotencia";
import { useOutletContext } from "react-router-dom";
import Alerts from "../../alerts";
import { ReportProblem } from "@mui/icons-material";
import StraightIcon from "@mui/icons-material/Straight";
import { use } from "react";
import AccordionSummaryFtth from "./AccordionSumaryFtth";
import AccordionSummaryDefault from "./AccordiaonSummaryDefault";
import MaisDetalhesDefault from "./moreOptionDefault";
import MaisDetalhesFtth from "./moreOptionFtth";

export default function CardPotencia(props, key) {
  const theme = useTheme();
  const [colorAlert, setColorAlert] = useState("");
  const { config, configLayer } = useOutletContext();
  const [pEntrada, setPEntrada] = useState("-");
  const [pSaida, setPSaida] = useState("-");
  const {
    mapId,
    element,
    conexao,
    index,
    expanded = false,
    setExpanded,
    unique = false,
    setFocusNodeId = () => {},
    setElementONUSelected = () => {},
    layer = "default",
    tipoEquipamento = null,
    children = () => {},
  } = props;

  const styles = {
    acordion: {
      padding: "5px",
      borderRadius: "5px",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 3px  #0000001a",
      position: "relative",
      border: "none",
      "&::before": {
        display: "none",
      },
    },

    textMedium: {
      color: theme.palette.color.textDisabled,
      fontSize: "0.85rem",
    },

    textLarge: { fontSize: "2em" },
    contentTab: { gap: "10px" },
    detailsCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    text: {
      fontSize: "0.85em",
      width: unique ? "250px" : "200px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
      textAlign: "left",
    },
    textAlerta: {
      fontSize: "0.85rem",

      color: theme.palette.color.textDisabled,
    },
    textHeaderAco: {
      fontSize: "0.9rem",

      color: theme.palette.color.text,
    },
  };
  function formatString(input) {
    return input
      .replace(/([A-Z])/g, " $1") // Adiciona um espaço antes de letras maiúsculas
      .replace(/^./, (str) => str.toUpperCase()) // Coloca a primeira letra em maiúscula
      .replace(/([a-z])([A-Z])/g, "$1 $2"); // Adiciona espaço entre letras minúsculas e maiúsculas
  }

  function handleChange(selected) {
    setExpanded(selected == expanded ? null : index);
    if (selected == expanded || selected == null) {
      setElementONUSelected(null);
    } else if (selected == index) {
      setElementONUSelected(conexao);
      setFocusNodeId(conexao.id);
    }
  }
  useEffect(() => {
    let status = conexao?.data?.status?.value || true;
    if (status) colorEnlace(conexao?.data?.potencia?.rx);
    else colorEnlace(-40);
    if (conexao?.data?.potencia?.rx != -40 && status)
      setPEntrada(conexao?.data?.potencia?.rx?.toFixed(2));
    else setPEntrada(-40);
    if (conexao?.data?.potencia?.tx != -40 && status)
      setPSaida(conexao?.data?.potencia?.tx?.toFixed(2));
    else setPSaida(-40);
  }, [conexao]);

  function colorEnlace(power) {
    if (power == null || power == 0 || power === Infinity || !config) {
      setColorAlert(theme.palette.enlaces.offline.main);
      return;
    }
    let result;
    if (layer == "default") {
      result = config?.escalas?.potencia
        .filter((scale) => parseFloat(scale?.label) >= power)
        .pop();
    } else if (layer == "ftth") {
      result = config?.escalas?.ftth
        .filter((scale) => parseFloat(scale?.label) >= power)
        .pop();
    }

    setColorAlert(result?.cor);
  }

  return (
    <Accordion
      key={key}
      disableGutters
      expanded={expanded == index || unique}
      onChange={() => handleChange(index)}
      sx={styles.acordion}
    >
      {Array.isArray(children) &&
        children[0] != null &&
        cloneElement(children[0], {
          ...props,
          colorAlert,
          theme,
          formatString,
        })}

      {!Array.isArray(children) &&
        cloneElement(children, { ...props, colorAlert, theme, formatString })}
      <AccordionDetails
        sx={{
          padding: "5px",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        {conexao.isValid == false && (
          <Alerts
            message="O equipamento não possui uma geolocalização cadastrada e não será exibida no mapa!"
            severity="warning"
          />
        )}

        <Paper sx={styles.detailsCard} elevation={0}>
          <Stack direction={"column"} gap={2}>
            <Typography textAlign={"center"} sx={styles.textAlerta}>
              {"Potência" + (tipoEquipamento ? " da " + tipoEquipamento : "")}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}>{pEntrada}</Typography>
                  <Typography color="primary" mb={"6px"} ml={"2px"}>
                    dBm
                  </Typography>
                </Stack>
                <Typography sx={styles.textMedium}>Entrada (Rx)</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}> {pSaida}</Typography>
                  <Typography color="primary" mb={"6px"} ml={"2px"}>
                    dBm
                  </Typography>
                </Stack>{" "}
                <Typography sx={styles.textMedium}> Saida (Tx)</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider variant="middle" />

          <StatusPotencia
            potenciaRx={pEntrada}
            variant={configLayer?.[mapId]}
          />
        </Paper>
        {conexao?.data?.localizacao && (
          <Accordion
            key={conexao.id + "localizacao"}
            disableGutters
            sx={{
              backgroundColor: theme.palette.background.paperLinkedMaps,
              boxShadow: "none",
              "&::before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh"
            >
              <Typography sx={styles.textHeaderAco}>Endereço</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(conexao?.data.localizacao || []).map(
                (nick, index) => (
                  <Fragment key={"dadosCliente" + index}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      width="100%"
                    >
                      <Typography
                        sx={styles.textAlerta}
                        fontSize={"0.7rem"}
                        width="170px"
                      >
                        {formatString(nick)}
                      </Typography>

                      <Typography
                        textAlign={"end"}
                        fontSize={"0.85rem"}
                        width={"200px"}
                      >
                        {conexao?.data?.localizacao?.[nick]}
                      </Typography>
                    </Stack>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                  </Fragment>
                )
              )}{" "}
            </AccordionDetails>
          </Accordion>
        )}
        {children[1] != null &&
          cloneElement(children[1], {
            ...props,
            colorAlert,
            theme,
            styles,
            formatString,
          })}
      </AccordionDetails>
    </Accordion>
  );
}

CardPotencia.AccordionSummaryFtth = AccordionSummaryFtth;
CardPotencia.AccordionSummaryDefault = AccordionSummaryDefault;
CardPotencia.MaisDetalhesFtth = MaisDetalhesFtth;
CardPotencia.MaisDetalhesDefault = MaisDetalhesDefault;
