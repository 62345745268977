import { useEffect, useMemo, useState } from "react";

import {
  Box,
  createSvgIcon,
  CssBaseline,
  Fade,
  Grow,
  ThemeProvider,
} from "@mui/material";

//import { easyMapsTheme } from "../../themes/themeEasyReport";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconLogo } from "../../assets/easyMaps/logoEasyMaps.svg";

//import { ReactComponent as PerfilReportSvg } from "../../assets/easyReport/iconSvg/perfilReport.svg";
import easyMapsTheme from "../../themes/easyMapsTheme";
import TopBar from "../../componentes/topBar";

import {
  Delete,
  Description,
  DescriptionOutlined,
  TuneOutlinedOutlined,
  EventNote,
  MapOutlined,
  RouteOutlined,
  WorkspacesOutlined,
} from "@mui/icons-material";
import checkToken from "../../services/CheckToken";
import SideBar from "../../componentes/sideBar";
import ConfiguracaoEasyMapsModal from "../../componentes/easyMaps/configModal";
import Config from "./config";
import api from "../../services/api";

function LayoutEasyMaps(params) {
  const { colorMode, setColorMode, socket, socketCliente, setCheckLogin } =
    params;
  const theme = useMemo(() => easyMapsTheme(colorMode), [colorMode]);
  const location = useLocation(); // Se estiver usando React Router
  const [snackbar, setSnackbar] = useState(null);
  const [openRightBar, setOpenRightBar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfig, setOpenConfig] = useState({ open: false, tab: 0 });
  const [showSidebar, setShowSidebar] = useState(true);
  const [config, setConfig] = useState(null);
  const [configLocal, setConfigLocal] = useState(null);
  const [configLayer, setConfigLayer] = useState(null);

  const navigate = useNavigate();

  const clientLogo = `${window?.config?.REACT_APP_LOGO_EASY_MAPS_URL || ""}`;

  const styles = {
    containerLayout: {
      display: "flex",
      width: "100%",
      height: "fit-content",
      flexDirection: "column",
    },
    containerBody: {
      display: "flex",
      width: "100%",
      height: "100%",
      minWidth: "350px",
    },
    containerOutlet: {
      flexGrow: 1,
      //paddingTop: 5, paddingInline: 3
      padding: showSidebar ? 4 : 0,
      gap: 3,
    },
  };
  //const PerfilReport = createSvgIcon(<PerfilReportSvg />);
  const rightBarPages = [
    {
      itemId: "1",
      label: "Grupo de mapas",
      link: "/easyMaps/grupos",
      icon: <WorkspacesOutlined />,
    },
    {
      openItem: true,
      itemId: "2",
      label: "Mapas",
      link: "/easyMaps/maps",
      icon: <MapOutlined />,
      nodes: [
        {
          itemId: "2.1",
          label: "Geolocalizado",
          link: "/easyMaps/mapas/geolocalizado",
        },
        {
          itemId: "2.2",
          label: "Topologia",
          link: "/easyMaps/mapas/topologia",
        },
      ],
    },
    {
      itemId: "3",
      label: "POP's",
      link: "/easyMaps/mapas/pop",
      icon: <RouteOutlined />,
    },
  ];
  useEffect(() => {
    setShowSidebar(!location.pathname.includes("/easyMaps/mapa/"));
  }, [location.pathname]);

  useEffect(() => {
    handleApiGetConfig();
  }, []);

  // ---- Garante caso o cofig seja mudando por outro instância, o cliente atualize
  useEffect(() => {
    function setChangeConfig(response) {
      if (response.status === 200) {
        setConfig(response?.data?.config || {});
      }
    }
    socketCliente.on("onChangeConfig", setChangeConfig);
    // Limpa o listener quando o componente desmonta ou dependências mudam
    return () => {
      socketCliente.off("onChangeConfig", setChangeConfig);
    };
  }, []);
  // atualizações do layer nos mapas
  useEffect(() => {
    if (!configLayer) return;
    sessionStorage.setItem("easyMapsLayer", JSON.stringify(configLayer));
  }, [configLayer]);

  useEffect(() => {
    if (configLayer != null) return;
    // Push the object directly
    let layers = sessionStorage.getItem("easyMapsLayer");
    setConfigLayer(JSON.parse(layers));
  }, []);
  // fim da atualização de layeres nos maps

  //--------atualizações das configurações globais e locais do easymaps------------
  useEffect(() => {
    if (!config) return;
    let configLocal =
      JSON.parse(localStorage.getItem("easyMapsConfig")) || null;
    if (configLocal?.animacao != null) return;

    setConfigLocal(JSON.parse(JSON.stringify({ animacao: config.animacao })));
  }, [config]);

  useEffect(() => {
    if (!configLocal) return;

    localStorage.setItem("easyMapsConfig", JSON.stringify(configLocal));
  }, [configLocal]);

  //--------fim atualizações das configurações globais e locais do easymaps------------

  // isso deve ser removido ao merjar com o guarda de rota (ALERT)
  useEffect(() => {
    setTimeout(() => {
      tokenValidate();
      checkPerfil();
    }, 200);

    function checkPerfil() {
      let perfilAlreadyExists =
        JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
      let satusPerfil =
        JSON.parse(localStorage.getItem("perfil"))?.status || false;
      if (satusPerfil === false || perfilAlreadyExists === false) {
        navigate("/cadastro");
      }
    }

    async function tokenValidate() {
      const data = await checkToken();

      if (!data) {
        navigate("/login");
      }
      setCheckLogin(true);
    }
  }, []);

  async function handleApiGetConfig() {
    try {
      const response = await api.get("/easyMaps/config/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar as configurações",
          severity: "error",
        });
      } else {
        if (response.data) {
          setConfig(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={styles.containerLayout}>
        <TopBar
          setOpenDrawer={setOpenDrawer}
          setOpenRightBar={setOpenRightBar}
          iconLogo={<IconLogo />}
          clientLogo={clientLogo}
          link={"/easyMaps"}
          socket={socket}
          socketCliente={socketCliente}
          colorMode={colorMode}
          setColorMode={setColorMode}
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          openRightBar={openRightBar}
          ocultar={!showSidebar}
          tools={<Config setOpenConfig={setOpenConfig} />}
        />
        <Box sx={styles.containerBody}>
          <SideBar
            items={rightBarPages}
            title="Navegação"
            openRightBar={openRightBar}
            setOpenRightBar={setOpenRightBar}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            iconLogo={<IconLogo />}
            clientLogo={clientLogo}
            ocultar={!showSidebar}
          />

          <Box component="main" sx={styles.containerOutlet}>
            <Outlet
              context={{
                config,
                configLocal,
                setConfigLocal,
                setOpenConfig,
                configLayer,
                setConfigLayer,
              }}
            />
          </Box>
        </Box>{" "}
      </Box>
      <ConfiguracaoEasyMapsModal
        open={JSON.parse(JSON.stringify(openConfig))}
        setOpen={setOpenConfig}
        config={config}
        setConfig={setConfig}
        setConfigLocal={setConfigLocal}
        tabDefault={openConfig.tab}
      />
    </ThemeProvider>
  );
}

export default LayoutEasyMaps;
