import { useEffect, useState, useRef } from "react";
import SliderBarMaps from "../../../../componentes/easyMaps/sideBarEasyMaps";
import api from "../../../../services/apiPublic";
import {
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";
import "../../../../componentes/leaflet-migrations";
import "../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import LeafletMapa from "./leaflatMapa";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../../../componentes/alertModal";
import LoadingUpdate from "../../../../componentes/easyMaps/loadingUpdate";

export default function MapaGeolocalizadoPublic() {
  const tipo = "easymon";
  const mapRef = useRef();
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState(null);
  const [elements, setElements] = useState([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true);
  const navigate = useNavigate();
  const { groupMapsId, id } = useParams();
  const [sinc, setSinc] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const { socket, config, configLayer, setConfigLayer } = useOutletContext();

  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 1500,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.mode != "dark" ? "#e0e0e093" : "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
  };

  useEffect(() => {
    if (elementSelected == null || editMode == true) return;

    setElementSelected({
      ...elements.find((ele) => ele.id == elementSelected.id),
    });
  }, [elements]);

  useEffect(() => {
    setLoadingMap(true);
    inicializeMap();
    getIconDB();
    handleApiGetMaps();
  }, [id]);

  useEffect(() => {
    // atualiza as confi de layer do mapa para o default
    if (!configLayer?.[id]) {
      setConfigLayer((configLayer) => ({
        ...configLayer,
        [id]: "trafego",
      }));
    }
    let timeInterval = config?.atualizacao?.trafego || 60000;
    const interval = window.setInterval(() => {
      updateDataElementSocket();
    }, timeInterval);

    return () => window.clearInterval(interval);
  }, [id, config]);

  async function inicializeMap() {
    await Promise.all([handleApiGetMap(id), handleApiGetElements(id)]);
    setLoadingMap(false);
  }
  async function handleApiGetMap(id) {
    try {
      setOpenAlertModal(null);
      const response = await api.post("/easyMaps/public/map/findMapById", {
        mapId: id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        if (response.data.tipo != 0) navigate("/easyMaps");
        else setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/pages/notFound");
      if (error?.response?.status == 401)
        setOpenAlertModal({
          open: true,
          severity: "error",
          text: "Você não tem permissão para acessar este mapa, solicite permissão ao administrador",
        });
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }
  const extractInterfaceName = (key) =>
    key?.match(/\[([^\],]+?(?=\s\-|,|\]))/)?.[1];

  const findSelectedInterface = (items) => {
    const selectedItem = items?.find((item) => {
      const key = item.key_?.toLowerCase();
      return (
        key?.includes("inoctets") ||
        key?.replace(/_/g, "").includes("rxpower") ||
        key?.includes("outoctets") ||
        key?.replace(/_/g, "").includes("txpower")
      );
    });
    if (!selectedItem) return "";
    return extractInterfaceName(selectedItem?.key_);
  };
  const searchItem = (itens, tipo) => {
    const item = itens.find((item) =>
      item.key_?.toLowerCase().replace(/_/g, "").includes(tipo)
    );
    return item;
  };

  async function updateDataElementSocket() {
    try {
      setSinc(true);
      socket.emit("getDataElementsMap", { mapId: id }, (response) => {
        setSinc(false);
        if (response.status !== 200) {
          setSnackbar({
            children: "Error: Não foi possível atualizar os elemntos",
            severity: "error",
          });
        } else {
          let responseData = response?.data;

          //Atualiza os elementos

          setElements((elements) => {
            elements.map((ele) => {
              let elemntoUpdate = responseData?.find(
                (eleUpdate) => eleUpdate?.elementId == ele?.id
              );

              //atualiza os alertas
              ele.alerts = elemntoUpdate?.alerts;
              //adicionar ping, snmp e latẽncia em data
              if (!ele?.data) ele["data"] = {};

              let itens = elemntoUpdate?.itensHosts || [];

              ele.data["itens"] = itens?.filter(
                (con) =>
                  con?.key_?.toLowerCase() != "icmppingloss" &&
                  con?.key_?.toLowerCase() != "snmp" &&
                  con?.key_?.toLowerCase() != "icmppingsec" &&
                  con?.key_?.toLowerCase() != "icmpping"
              );

              ele.data["perdaPing"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingloss"
              );

              ele.data["snmp"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "snmp"
              );

              ele.data["latencia"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingsec"
              );
              ele.data["ping"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmpping"
              );
            });
            return [...elements];
          });

          // Atualiza as conexões

          let connectionsUpdate = responseData.flatMap(
            (ele) => ele.connections
          );

          setConnections((connections) => {
            return [
              ...connections.map((conn) => {
                let connectionUpdate = connectionsUpdate.find(
                  (connUp) => connUp.id == conn.id
                );
                if (!connectionUpdate) return conn;
                if (!conn.data) conn["data"] = {};

                // obtem a interface da conexão
                conn.data["interface"] = findSelectedInterface(conn?.item);

                // --- adiciona as informações de tráfego em data ---
                let valueTrafficRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "inoctets")?.lastvalue
                );

                let valueTrafficTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "outoctets")?.lastvalue
                );

                conn.data["trafego"] = {
                  rx: isNaN(valueTrafficRx) ? "-" : +valueTrafficRx.toFixed(2),

                  tx: isNaN(valueTrafficTx) ? "-" : +valueTrafficTx.toFixed(2),

                  units: valueTrafficTx?.units || null,
                };

                // --- adiciona as informações de potência em data ---
                let valuePowerRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "rxpower")?.lastvalue
                );
                let valuePowerTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "txpower")?.lastvalue
                );
                conn.data["potencia"] = {
                  rx: isNaN(valuePowerRx) ? "-" : +valuePowerRx.toFixed(2),

                  tx: isNaN(valuePowerTx) ? "-" : +valuePowerTx.toFixed(2),

                  unit: valuePowerTx?.units || null,
                };

                // --- adiciona as demais informações em data ---

                conn.data["status"] = connectionUpdate?.status;
                conn.data["itens"] =
                  connectionUpdate?.itens
                    ?.filter(
                      (con) =>
                        !con.key_?.toLowerCase()?.includes("inoctets") &&
                        !con.key_?.toLowerCase()?.includes("outoctets") &&
                        !con.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("rxpower") &&
                        !con.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("txpower") &&
                        !con.key_?.toLowerCase()?.includes("operstatus")
                    )
                    ?.map((item) => ({
                      name: item.name,
                      lastValue: item.lastvalue,
                      units: item.units,
                    })) || [];

                return conn;
              }),
            ];
          });
        }
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar",
        severity: "error",
      });
    }
  }

  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/public/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapas(
            response.data.map((map) => ({
              nome: map.titulo,
              id: map.id,
              tipo: map.tipo,
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetElements(id) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/easyMaps/public/element/findMany", {
        mapId: id,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        setElements(response.data.responseAlerts);
        setConnections(response.data.connections);
        updateDataElementSocket();
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function getIconDB() {
    try {
      const response = await api.get("/easyMaps/public/img/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <>
      <Stack direction="row">
        <SliderBarMaps
          map={map}
          iconsElements={iconsElements}
          mapRef={mapRef}
          elements={elements}
        />
        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                }
              : styles.fullScreem
          }
          width="100%"
        >
          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" />
              <Typography
                fontSize="14px"
                color={theme.palette.color.textEasyMaps}
              >
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}
          <LoadingUpdate
            visible={sinc && !loadingMap}
            elementSelected={!!elementSelected}
          />

          <LeafletMapa
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            socketCliente={socket}
            ref={mapRef}
            elements={elements}
            connections={JSON.parse(JSON.stringify(connections))}
            setElementSelected={setElementSelected}
            elementSelected={elementSelected}
            iconsElements={iconsElements}
            setIconsElement={setIconsElements}
            mapId={id}
            title={map?.titulo}
            element={editMode ? elementSelected : null}
            setElements={setElements}
            setConnections={setConnections}
            produtos={produtos}
            api={api}
            setSnackbar={setSnackbar}
            mapas={mapas}
            setMapas={setMapas}
            editMode={editMode}
            setEditMode={setEditMode}
            updateDataElementSocket={updateDataElementSocket}
          />
        </Stack>
      </Stack>
      <AlertModal
        openModal={openAlertModal?.open ?? false}
        setOpenModal={(data) => setOpenAlertModal({ open: data })}
        severity={openAlertModal?.severity}
        textContent={openAlertModal?.text}
        handleButton={() => {
          setOpenAlertModal({ open: false });
          navigate(-1);
        }}
        buttonText={"fechar"}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
