import {
  BorderBottom,
  MultipleStop,
  SignalCellularAlt,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fontGrid } from "@mui/material/styles/cssUtils";
import { use, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

const DescriptionBar = (
  { elementSelected = false, variant = "default", layer = "trafego" },
  key
) => {
  const { config } = useOutletContext();
  const [cores, setCores] = useState([]);
  const [unidade, setUnidade] = useState("");
  let theme = useTheme();

  useEffect(() => {
    if (!config) return;

    if (variant === "default" && layer === "trafego") {
      setCores(config.escalas.trafego);
      setUnidade("%");
    } else if (variant === "default" && layer === "potencia") {
      setCores(config.escalas.potencia);
      setUnidade("dBm");
    } else if (variant === "ftth") {
      setCores(config.escalas.ftth);
      setUnidade("dBm");
    }
  }, [config, layer]);

  const styles = {
    textLayer: {
      fontSize: "12px",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: 0.5,
      color: alpha(theme.palette.text.disabled, 0.8),
      backgroundColor: theme.palette.background.default,
      p: 1,
      width: "fit-content",
      height: "35px",
    },
    container: {
      maxHeight: useMediaQuery(theme.breakpoints.up("lg")) ? "35px" : null,
      position: "absolute",
      left: elementSelected ? 0 : null,
      right: elementSelected ? null : 0,
      bottom: 0,
      zIndex: 10,
      alignItems: "end",

      flexDirection: useMediaQuery(theme.breakpoints.up("lg"))
        ? elementSelected
          ? "row-reverse"
          : "row"
        : "colum",
    },
    bar: {
      p: 0.6,
      backgroundColor: theme.palette.background.default,
      backgroundColor: theme.palette.background.default,

      flexDirection: "row",
    },
    box: {
      minHeight: "10px",
      minWidth: "60px",
      ml: "-1px",
    },
  };

  return (
    <>
      <Stack sx={styles.container} key={key}>
        {/* <Stack sx={styles.textLayer}>
          {layer === "potencia" && (
            <>
              <SignalCellularAlt sx={{ fontSize: "19px" }} />
              {"Camada de Potência"}
            </>
          )}
          {layer === "trafego" && (
            <>
              <MultipleStop sx={{ fontSize: "19px" }} />
              {"Camada de Tráfego"}
            </>
          )}
        </Stack> */}

        <Stack sx={styles.bar}>
          {cores?.map((cor, index) => (
            <Stack
              key={"barColor" + index}
              direction="row"
              sx={{ justifyContent: "center", height: "25px" }}
            >
              <Divider orientation="vertical" />
              <Stack gap={0.4}>
                <Box
                  sx={[
                    styles.box,
                    {
                      backgroundColor: cor.cor,
                    },
                  ]}
                />
                <Typography sx={{ fontSize: "10px", ml: "2px" }}>
                  {" "}
                  {cor.label + (index != 0 ? unidade : "")}
                </Typography>
              </Stack>
            </Stack>
          ))}
          <Divider orientation="vertical" sx={{ height: "25px" }} />
        </Stack>
      </Stack>
    </>
  );
};

export default DescriptionBar;
