import { useEffect, useState, useRef } from "react";
import SliderBarMaps from "../../../../componentes/easyMaps/sideBarEasyMaps";
import api from "../../../../services/api";
import {
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  useTheme,
  darken,
} from "@mui/material";
import { useParams } from "react-router-dom";
import "../../../../componentes/leaflet-migrations";
import "../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import LeafletMapa from "./leaflatMapa";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { Warning } from "@mui/icons-material";
import { ReactComponent as BarIcon } from "../../../../assets/easyMaps/titlebar.svg";
import LoadingUpdate from "../../../../componentes/easyMaps/loadingUpdate";
export default function MapaGeolocalizado({ socketCliente }) {
  const tipo = "easymon";
  const mapRef = useRef();
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState(null);
  const [elements, setElements] = useState([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true);
  const navigate = useNavigate();
  const [checkChanges, setCheckChanges] = useState(false);
  const { config, configLayer, setConfigLayer } = useOutletContext();
  const [sinc, setSinc] = useState(false);
  const { groupMapsId, id } = useParams();
  const editmodeRef = useRef();

  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 1500,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.mode != "dark" ? "#e0e0e093" : "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
    editMode: {
      position: "absolute",
      zIndex: 1001,
      fontSize: "13px",
      border:
        (fullScreem ? "4px" : "2px") + " solid " + theme.palette.secondary.main,
      display: "flex",
      alignItems: "stop",
      justifyContent: "center",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      //boxShadow: "inset 0px 0px 10px 0px " + theme.palette.color.alerts.info,
    },
    dotEditMode: {
      mt: "-2px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "fit-content",
    },
    textEditMode: {
      mt: "-27px",
      color: "white",
      fontSize: "15px",
      width: "fit-content",
    },
  };
  useEffect(() => {
    editmodeRef.current = editMode;
    if (!editMode) {
      updateDataElementSocket();
    }
  }, [editMode]);

  useEffect(() => {
    if (elementSelected == null || editMode == true) return;
    setElementSelected({
      ...elements.find((ele) => ele.id == elementSelected.id),
    });
  }, [elements]);

  useEffect(() => {
    if (connectionSelected == null || checkChanges) return;
    setConnectionSelected({
      ...connections.find((conn) => conn.id == connectionSelected.id),
    });
  }, [connections]);

  useEffect(() => {
    // atualiza as confi de layer do mapa para o default
    if (!configLayer?.[id]) {
      setConfigLayer((configLayer) => ({
        ...configLayer,
        [id]: "trafego",
      }));
    }
    let timeInterval = config?.atualizacao?.trafego || 60000;
    const interval = window.setInterval(() => {
      updateDataElementSocket();
    }, timeInterval);

    return () => window.clearInterval(interval);
  }, [id, config]);

  useEffect(() => {
    setLoadingMap(true);
    inicializeMap();
    getIconDB();
    handleApiGetProdutos(tipo);
    handleApiGetMaps();
  }, [id]);
  async function inicializeMap() {
    await Promise.all([handleApiGetMap(id), handleApiGetElements(id)]);
    setLoadingMap(false);
  }
  async function handleApiGetMap(id) {
    try {
      const response = await api.post("/easyMaps/map/findMapById", {
        mapId: id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        if (response.data.tipo != 0) navigate("/easyMaps");
        else setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/pages/notFound");
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }
  const extractInterfaceName = (key) =>
    key?.match(/\[([^\],]+?(?=\s\-|,|\]))/)?.[1];

  const findSelectedInterface = (items) => {
    const selectedItem = items?.find((item) => {
      const key = item.key_?.toLowerCase();
      return (
        key?.includes("inoctets") ||
        key?.replace(/_/g, "").includes("rxpower") ||
        key?.includes("outoctets") ||
        key?.replace(/_/g, "").includes("txpower")
      );
    });
    if (!selectedItem) return "";
    return extractInterfaceName(selectedItem?.key_);
  };

  const searchItem = (itens, tipo) => {
    const item = itens.find((item) =>
      item.key_?.toLowerCase().replace(/_/g, "").includes(tipo)
    );
    return item;
  };

  async function updateDataElementSocket() {
    if (editmodeRef.current) return;
    try {
      setSinc(true);
      socketCliente.emit("getDataElementsMap", { mapId: id }, (response) => {
        setSinc(false);
        if (response.status !== 200) {
          setSnackbar({
            children: "Error: Não foi possível atualizar os elemntos",
            severity: "error",
          });
        } else {
          let responseData = response?.data;

          //Atualiza os elementos

          setElements((elements) => {
            elements.map((ele) => {
              let elemntoUpdate = responseData?.find(
                (eleUpdate) => eleUpdate?.elementId == ele?.id
              );

              //atualiza os alertas
              ele.alerts = elemntoUpdate?.alerts;
              //adicionar ping, snmp e latẽncia em data
              if (!ele?.data) ele["data"] = {};

              let itens = elemntoUpdate?.itensHosts || [];

              ele.data["itens"] = itens?.filter(
                (con) =>
                  con?.key_?.toLowerCase() != "icmppingloss" &&
                  con?.key_?.toLowerCase() != "snmp" &&
                  con?.key_?.toLowerCase() != "icmppingsec" &&
                  con?.key_?.toLowerCase() != "icmpping"
              );

              ele.data["perdaPing"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingloss"
              );

              ele.data["snmp"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "snmp"
              );

              ele.data["latencia"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmppingsec"
              );
              ele.data["ping"] = itens?.filter(
                (con) => con?.key_?.toLowerCase() == "icmpping"
              );
            });
            return [...elements];
          });

          // Atualiza as conexões

          let connectionsUpdate = responseData.flatMap(
            (ele) => ele.connections
          );

          setConnections((connections) => {
            return [
              ...connections.map((conn) => {
                let connectionUpdate = connectionsUpdate.find(
                  (connUp) => connUp.id == conn.id
                );
                if (!connectionUpdate) return conn;
                if (!conn.data) conn["data"] = {};

                // obtem a interface da conexão
                conn.data["interface"] = findSelectedInterface(conn?.item);

                // --- adiciona as informações de tráfego em data ---
                let valueTrafficRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "inoctets")?.lastvalue
                );

                let valueTrafficTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "outoctets")?.lastvalue
                );

                conn.data["trafego"] = {
                  rx: isNaN(valueTrafficRx) ? "-" : +valueTrafficRx.toFixed(2),

                  tx: isNaN(valueTrafficTx) ? "-" : +valueTrafficTx.toFixed(2),

                  units: valueTrafficTx?.units || null,
                };

                // --- adiciona as informações de potência em data ---
                let valuePowerRx = parseFloat(
                  searchItem(connectionUpdate?.itens, "rxpower")?.lastvalue
                );
                let valuePowerTx = parseFloat(
                  searchItem(connectionUpdate?.itens, "txpower")?.lastvalue
                );
                conn.data["potencia"] = {
                  rx: isNaN(valuePowerRx) ? "-" : +valuePowerRx.toFixed(2),

                  tx: isNaN(valuePowerTx) ? "-" : +valuePowerTx.toFixed(2),

                  unit: valuePowerTx?.units || null,
                };

                // --- adiciona as demais informações em data ---

                conn.data["status"] = connectionUpdate?.status;
                conn.data["itens"] =
                  connectionUpdate?.itens
                    ?.filter(
                      (con) =>
                        !con?.key_?.toLowerCase()?.includes("inoctets") &&
                        !con?.key_?.toLowerCase()?.includes("outoctets") &&
                        !con?.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("rxpower") &&
                        !con?.key_
                          ?.toLowerCase()
                          ?.replace(/_/g, "")
                          ?.includes("txpower") &&
                        !con?.key_?.toLowerCase()?.includes("operstatus")
                    )
                    ?.map((item) => ({
                      name: item.name,
                      lastValue: item.lastvalue,
                      units: item.units,
                    })) || [];

                return conn;
              }),
            ];
          });
        }
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar",
        severity: "error",
      });
    }
  }

  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapas(
            response.data.map((map) => ({
              nome: map.titulo,
              id: map.id,
              tipo: map.tipo,
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetElements(id) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/easyMaps/element/findMany", {
        mapId: id,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        setElements(response.data.responseAlerts);
        setConnections(response.data.connections);
        updateDataElementSocket();
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetProdutos(tipo) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/cliente/produto/findMany", {
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function getIconDB() {
    try {
      const response = await api.get("/easyMaps/img/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <>
      <Stack direction="row">
        <SliderBarMaps
          map={map}
          iconsElements={iconsElements}
          mapRef={mapRef}
          elements={elements}
        />

        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                }
              : styles.fullScreem
          }
          width="100%"
        >
          {editMode && (
            <Box sx={styles.editMode}>
              <Box sx={styles.dotEditMode}>
                <BarIcon width={"250px"} fill={theme.palette.secondary.main} />
                <Typography sx={styles.textEditMode}>Modo de Edição</Typography>
              </Box>
            </Box>
          )}

          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" disableShrink />
              <Typography
                fontSize="14px"
                color={theme.palette.color.textEasyMaps}
              >
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}
          <LoadingUpdate
            visible={sinc && !loadingMap}
            elementSelected={!!elementSelected}
          />

          <LeafletMapa
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            socketCliente={socketCliente}
            ref={mapRef}
            elements={elements}
            connections={JSON.parse(JSON.stringify(connections))}
            setElementSelected={setElementSelected}
            connectionSelected={connectionSelected}
            setConnectionSelected={setConnectionSelected}
            elementSelected={elementSelected}
            iconsElements={iconsElements}
            setIconsElement={setIconsElements}
            mapId={id}
            title={map?.titulo}
            element={editMode ? elementSelected : null}
            setElements={setElements}
            setConnections={setConnections}
            produtos={produtos}
            api={api}
            setSnackbar={setSnackbar}
            mapas={mapas}
            setMapas={setMapas}
            editMode={editMode}
            setEditMode={setEditMode}
            updateDataElementSocket={() => {}}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
          />
        </Stack>
      </Stack>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transitionDuration={1}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
