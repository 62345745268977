import { ExpandMore, ReportProblem, Straight } from "@mui/icons-material";
import {
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const AccordionSummaryDefault = ({
  element,
  conexao,
  elements,
  colorAlert,
  index,
  expanded,
  unique,
  theme,
}) => {
  const [reference, setReference] = useState(null);
  const [destination, setDestination] = useState(null);
  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },
    text: {
      fontSize: "0.85em",
      width: "100%",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
      textAlign: "left",
    },
  };
  useEffect(() => {
    let reference = elements.find((ele) => ele.id === conexao.referenceId);
    let destination = elements.find((ele) => ele.id === conexao.destinationId);
    setReference(reference?.titulo);
    setDestination(destination?.titulo);
  }, [elements, conexao]);
  return (
    <AccordionSummary
      expandIcon={!unique ? <ExpandMore /> : null}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      sx={{
        padding: "0px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        ml={1}
      >
        <Box>
          <Box sx={[styles.line, { backgroundColor: colorAlert }]}></Box>

          <Stack direction={"column"} gap={1} ml={1.5}>
            <>
              <Tooltip
                title={
                  conexao?.referenceId === conexao?.id ? reference : destination
                }
              >
                <Typography sx={styles.text}>
                  {conexao?.referenceId === conexao?.id
                    ? reference
                    : destination}
                </Typography>
              </Tooltip>
              <Divider width={"80px"} />

              <Tooltip
                title={
                  conexao?.referenceId !== conexao?.id ? reference : destination
                }
              >
                <Typography sx={styles.text}>
                  {conexao?.referenceId !== conexao?.id
                    ? reference
                    : destination}
                </Typography>
              </Tooltip>
            </>
          </Stack>
        </Box>
        <Straight
          sx={{
            fontSize: "30px",
            transform:
              conexao?.referenceId == conexao?.id ? "rotate(180deg)" : null,
            mr: 0,
            color: theme.palette.mode == "dark" ? "#565656" : "#9A9A9A",
          }}
        />
      </Stack>
    </AccordionSummary>
  );
};

export default AccordionSummaryDefault;
