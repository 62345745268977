import { ArrowForward, CorporateFare, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../../assets/headerHome.jpeg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logoMw from "../../../assets/logo4.png";
import logoMw2 from "../../../assets/logo5.png";

export default function AccountType() {
  const [pageValue, setPageValue] = useState(null);
  const [login, setLogin] = useState("");
  const navigate = useNavigate();

  const logo = `${window?.config?.REACT_APP_LOGO_SMALL_URL || logoMw}`;
  const logoLight = `${window?.config?.REACT_APP_LOGO_SMALL_URL || logoMw2}`;
  const isPartner = window?.config?.REACT_APP_IS_PARTNER === "true" || false;
  const backgroundImage = `${
    window?.config?.REACT_APP_BACKGROUND_IMAGE_URL || background
  }`;

  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const styles = {
    LoadingLogo: {
      position: "relative",
      margin: "50px",
    },
    CircularProgress: {
      color: "primary",
      position: "absolute",
      top: -9,
      left: -6,
      zIndex: 1,
    },
    ContainerBox: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperPhoto: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "50vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
      objectFit: "cover",
    },
    boxTitlePhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    title: {
      fontSize: { lg: "3em", md: "2em" },
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
      maxWidth: "max-content",
      textWrap: "wrap",
    },
    subTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    boxHeaderAndForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      height: "100%",
      gap: 6,
      padding: 4,
      // backgroundColor: "red",
      minWidth: "330px",
      width: { xs: "100vw", md: "50vw", lg: "50vw" },
    },
    boxHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      minWidth: "330px",
      padding: 2,
      gap: "auto",
    },
    buttonText: { color: "text.primary", textTransform: "none" },
    icon: { height: "auto", width: "auto" },
    img: { height: "auto", width: "100px" },
    stackForm: {
      width: "100%",
      maxWidth: "400px",
      height: "100vh",
      minHeight: "50vh",
      maxHeight: "600px",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    boxForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
      width: { xs: "80vw", md: "100%" },
      maxWidth: "400px",
      gap: 2,
    },
    boxTitles: { gap: 1, display: "flex", flexDirection: "column" },
    boxFieldAndButton: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 2,
      alignSelf: "stretch",
      width: "100%",
    },

    textField: {
      width: "100%",
      marginTop: 2,
    },
    button: {
      width: "100%",
      borderRadius: "5px",
      marginTop: "25px",
      height: "40px",
      marginBottom: "20px",
      fontSize: 16,
      textTransform: "none",
    },
    titleForm: {
      fontSize: "1.3em",
      fontWeight: 400,
      color: "text.primary",
    },
    subTitleForm: {
      fontSize: "0.875em",
      fontWeight: 400,
      color: "text.secondary",
    },
    formCodeContent: {
      display: "flex",
      padding: "12px 0px",
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      alignSelf: "stretch",
      width: "100%",
    },
    codeErrorMsg: {
      fontSize: "13px",
      marginTop: "-10px",
      color: "#e83750",
      marginBottom: "-20px",
    },
    stackBox: {
      flexDirection: "row",
      gap: { xs: 2, xsm: 5 },
      width: "100%",
    },
    iconType: {
      width: "80px",
      height: "80px",
      color: "text.disabled",
    },
    typography: { color: "text.primary", textAlign: "center" },
  };

  useEffect(() => {
    if (!localStorage.getItem("1")) {
      navigate("/login");
    }
    let resultLogin = localStorage.getItem("login");
    setLogin(JSON.parse(resultLogin));
  });

  const handleNavigateCadastro = () => {
    if (pageValue != null) {
      localStorage.setItem("pageValue", pageValue);
      navigate("/cadastro");
    }
  };
  const handleVoltar = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.boxHeaderAndForm}>
          <Box sx={styles.boxHeader}>
            <Button
              variant="text"
              sx={styles.buttonText}
              onClick={() => handleVoltar()}
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>

            <Icon style={styles.icon}>
              <img
                alt=""
                src={colorMode === "light" ? logo : logoLight}
                style={styles.img}
              />
            </Icon>
          </Box>
          <Stack sx={styles.stackForm}>
            <Box sx={styles.boxForm}>
              <Box sx={styles.boxTitles}>
                <Typography sx={styles.titleForm}>
                  {`Olá, ${login}. Faça o cadastro da sua conta no sistema!`}
                </Typography>

                <Typography sx={styles.subTitleForm}>
                  Qual tipo de conta você deseja cadastrar?
                </Typography>
              </Box>
              <Box sx={styles.boxFieldAndButton}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={pageValue}
                  onChange={(event, newValue) => {
                    setPageValue(newValue);
                  }}
                  width={"100%"}
                >
                  <Stack sx={styles.stackBox}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        // backgroundColor:"red",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: 2,
                        width: "auto",
                        borderRadius: 2,
                        paddingX: 4,
                        border:
                          pageValue == 0
                            ? `1px solid ${theme.palette.primary.main}}`
                            : `1px solid ${theme.palette.stroke.main}`,
                      }}
                      onClick={() => setPageValue(0)}
                    >
                      <Person sx={styles.iconType} />
                      <Typography sx={styles.typography}>
                        Pessoa Física
                      </Typography>
                      <Radio value={0} />
                    </Box>

                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: 2,
                        width: "auto",
                        borderRadius: 2,
                        paddingX: 4,
                        border:
                          pageValue == 1
                            ? `1px solid ${theme.palette.primary.main}}`
                            : `1px solid ${theme.palette.stroke.main}`,
                      }}
                      onClick={() => setPageValue(1)}
                    >
                      <CorporateFare sx={styles.iconType} />
                      <Typography sx={styles.typography}>
                        Pessoa Jurídica
                      </Typography>
                      <Radio value={1} />
                    </Box>
                  </Stack>
                </RadioGroup>
                <Button
                  variant="contained"
                  endIcon={<ArrowForward />}
                  onClick={() => handleNavigateCadastro()}
                  disabled={pageValue == null}
                  sx={styles.button}
                >
                  Continuar
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={styles.paperPhoto}>
          {!isPartner && (
            <Box sx={styles.boxTitlePhoto}>
              <Typography sx={styles.title}>
                Monitoramento eficiente e confiável.
              </Typography>
              <br></br>
              <Typography sx={styles.subTitle}>
                Monitoramento de infraestrutura de redes para provedores de
                internet, com suporte confiável e atencioso.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
}
