import {
  alpha,
  Box,
  Button,
  Fade,
  Grow,
  IconButton,
  Paper,
  Popper,
  Slide,
  Stack,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import useStyles from "./styles";
import LayersIcon from "@mui/icons-material/Layers";
import { useState, useRef, useEffect } from "react";
import {
  Add,
  SignalCellularConnectedNoInternet2Bar,
  SwapHoriz,
  MultipleStop,
  SignalCellularAlt,
} from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";

const LayerBar = ({
  mapId = null,
  elementSelected = null,
  editMode = false,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme, elementSelected);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const { configLayer, setConfigLayer, config } = useOutletContext();

  const handleChange = (layer) => {
    setConfigLayer((data) => {
      if (data?.[mapId]) {
        data[mapId] = layer;
      } else {
        data = { ...data, [mapId]: layer };
      }
      return { ...data };
    });
  };

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (
      event.relatedTarget instanceof Node &&
      !popperRef?.current?.contains(event.relatedTarget) &&
      !event?.currentTarget?.contains(event.relatedTarget)
    ) {
      setAnchorEl(null);
    }
  };

  const Layer = ({ icon: Icon, titulo, selected, setSelected = () => {} }) => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems={"center"}
        gap={0.2}
        sx={{ width: "70px" }}
      >
        <Button
          onClick={() => setSelected()}
          sx={{
            minWidth: "0px",
            textTransform: "none",
            justifyContent: "center",
            alignItems: "center",
            height: "50px",
            width: "50px",
            boxShadow: "0px 0px 5px  #0000000a",
            p: "0px",
            backgroundColor: theme.palette.background.paperSelectedMaps,
            ":hover": {
              boxShadow: "0px 0px 5px  #0000002d",
            },
          }}
        >
          <Icon
            sx={{
              color: selected ? null : theme.palette.color.icons,
              fontSize: "25px",
            }}
          />
        </Button>
        <Typography
          sx={{
            textAlign: "center",
            width: "80px",
            fontSize: "12px",
            color: selected ? theme.palette.primary.main : null,
          }}
        >
          {titulo}
        </Typography>
      </Stack>
    );
  };
  if (editMode) return null;

  return (
    <></>
    // <>
    //   <Button
    //     onMouseEnter={handleToggle}
    //     onMouseLeave={handleClose}
    //     sx={styles.button}
    //   >
    //     <LayersIcon
    //       sx={{
    //         fontSize: "30px",
    //         color: theme.palette.color.icons,
    //         pointerEvents: "none",
    //       }}
    //     />
    //     <Typography
    //       sx={{
    //         fontSize: "11px",
    //         textTransform: "none",
    //         color: theme.palette.text.primary,
    //       }}
    //     >
    //       Camadas
    //     </Typography>
    //   </Button>

    //   <Popper
    //     open={Boolean(anchorEl)}
    //     anchorEl={anchorEl}
    //     onMouseOut={handleClose}
    //     ref={popperRef}
    //     placement={"right-start"}
    //     transition
    //     modifiers={[
    //       {
    //         name: "offset",
    //         options: {
    //           offset: [-22, 0],
    //         },
    //       },
    //     ]}
    //   >
    //     {({ TransitionProps }) => (
    //       <Grow {...TransitionProps} direction={"up"} timeout={350}>
    //         <Box pl={"5px"}>
    //           <Box sx={styles.container}>
    //             <Layer
    //               selected={configLayer?.[mapId] == "potencia"}
    //               icon={SignalCellularAlt}
    //               titulo="Potência"
    //               setSelected={() => handleChange("potencia")}
    //             />
    //             <Layer
    //               selected={configLayer?.[mapId] == "trafego"}
    //               icon={MultipleStop}
    //               titulo="Tráfego"
    //               setSelected={() => handleChange("trafego")}
    //             />
    //           </Box>
    //         </Box>
    //       </Grow>
    //     )}
    //   </Popper>
    // </>
  );
};

export default LayerBar;
