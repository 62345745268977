import {
  Autocomplete,
  Button,
  Divider,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
  createSvgIcon,
  useTheme,
  Tooltip,
  Chip,
  alpha,
  InputAdornment,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import IconTransform from "../iconsElement";
import {
  AnimationOutlined,
  CancelOutlined,
  CloseOutlined,
  Delete,
  DeleteOutline,
  DoubleArrowOutlined,
  KeyboardDoubleArrowRightOutlined,
  LineStyle,
  SaveOutlined,
  ShapeLineOutlined,
  Timeline,
  ViewInArOutlined,
} from "@mui/icons-material";
import { ReactComponent as LinkedIcon } from "../../../assets/easyMaps/iconsSvg/Linked.svg";
import { ReactComponent as CapacitySvg } from "../../../assets/easyMaps/iconsSvg/capacidade.svg";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import NearMeIcon from "@mui/icons-material/NearMe";
import { LoadingButton } from "@mui/lab";
import { VirtualizedListbox } from "../vitualizationAutoComplete";

const lineTypes = ["reta", "ortogonal", "curvada"];
const animationTypes = ["pontilhada", "tracejada"];

const EnlaceSideBarEasyMaps = forwardRef(
  (
    {
      setEdgeSelected = () => {},
      setOpenAlertModal = () => {},
      connection = null,
      setConnection = () => {},
      edgeSelected,
      setSnackbar,
      api,
      produtos,
      elements,
      iconsElement,
      saveConnection,
      handleDelete = () => {},
      changeConnection,
      fullScreem = false,
      autoRouting = () => {},
      cleanRouting = () => {},
      loadRouting = false,
      setLoadRouting = () => {},
      tipoMapa = 0,
      checkChanges,
      setCheckChanges,
      loadingButton,
      typeElement = "Host",
    },
    ref
  ) => {
    const [line, setLine] = useState("reta");
    const [itens, setItens] = useState([]);
    const [link, setLink] = useState("");
    const [hostId, setHostId] = useState("");
    const [itemConnection, setItemConnection] = useState([]);
    const [otherItensConnection, setOtherItensConnection] = useState([]);
    const [capacidade, setCapacidade] = useState("");
    const [iconsElementPair, setIconsElementPair] = useState([]);
    const [animation, setAnimation] = useState("");
    const [loading, setLoading] = useState(false);
    const [elementsPair, setElementsPair] = useState([]);
    //interfaces
    const [interfaces, setInterfaces] = useState([]);
    const [interfaceSelected, setInterfaceSelected] = useState(null);
    //const previousChange = usePrevious(positionHandlers);
    const theme = useTheme();
    const CapacityIcon = createSvgIcon(
      <CapacitySvg height="100%" width="100%" />
    );
    useImperativeHandle(ref, () => ({
      update() {
        handleSave();
      },
    }));

    const styles = {
      stackOption: {
        flexDirection: "row",
        width: "100%",
        p: "5px",
        alignItems: "center",
      },
      textoLimitado: {
        display: "-webkit-box",
        WebkitLineClamp: 2, // Limita o texto a 2 linhas
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      text: {
        fontSize: "0.875rem",
        color: theme.palette.color.text,
      },
      buttonRoter: {
        padding: "10px",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
      },

      textButtonRoter: { textTransform: "none", fontSize: "0.9rem" },
      buttonBottom: {
        width: "33%",
        textTransform: "none",
      },
      paper: {
        borderRadius: "0px",
        height: "calc(100vh-65px)",
        position: "sticky",
        width: "340px!important",
        backgroundColor: theme.palette.background.sideBar,
      },
      header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 2,
        p: "15px",
      },
      container: {
        height: "70vh",
        overflow: "auto",
        paddingRight: "5px",
        gap: 1.5,
      },
      drawer: {
        position: "absolute",

        clipPath: "none",
        marginTop: "65px",
        flexShrink: 0,
        overflow: "visible",
        "& .MuiDrawer-paper": {
          //zIndex: 1202,
          //boxShadow: "0px 0px 10px  #0000001a",
          borderRadius: "7px",
          color: theme.palette.text.primary,
          mr: "10px",
          height: fullScreem ? "calc(100vh - 20px)" : "calc(100vh - 85px)",
          width: "350px",
          padding: "10px 10px",
          marginTop: fullScreem ? "10px" : "75px",
          overflow: "visible",
          backgroundColor: theme.palette.background.sideBar,
          border: "1px solid " + theme.palette.color.appBarBorder,
          display: "flex",
          gap: 2,
        },
      },
    };

    useEffect(() => {
      if (connection != undefined) {
        let imgsPair = [];
        const reference = elements.find(
          ({ id }) => id == connection?.referenceId
        );
        const destination = elements.find(
          ({ id }) => id == connection?.destinationId
        );
        let findPair = [reference, destination];
        imgsPair = [reference?.img, destination?.img];
        if (Array.isArray(findPair) && findPair.length === 2) {
          setElementsPair(findPair);
          setIconsElementPair([
            iconsElement?.find((icon) => imgsPair[0] === icon?.id),
            iconsElement?.find((icon) => imgsPair[1] === icon?.id),
          ]);
        }

        setCapacidade(connection?.config?.capacidade ?? "");
        setAnimation(connection?.config?.animation ?? "desativada");
        setLine(connection?.config?.type || lineTypes[0]);
        // devido a possibilidade de criar conexões sem itens
        let hostConnId = connection?.config?.hostOriginId; // as novas conexões carregaram esse campo (cardConnection)
        if (!hostConnId) {
          //verifica o host pelos itens da conexão
          if (Array.isArray(connection.item) && connection.item.length > 0) {
            setHostId(connection?.item[0]?.hostOriginId);
          } else {
            let elementoReference = elements.find(
              (a) => a.id == connection.referenceId
            );
            if (elementoReference?.tipo == 0) {
              setHostId(
                elementoReference?.elementConfig?.config?.hosts[0]?.hostid
              );
            }
            // Aqui fica sério pq em mapas ou pops temos muitos hosts e não dá para saber qual deles é a origim se nã tiver itens ou o hostOriginId,
            //nesse caso os usuarios precisaram recolocar o host de origem na conexão para que carregem o campo hostOriginId
          }
        } else {
          setHostId(hostConnId);
        }
        const produtoId = elements.find(
          (ele) => ele.id == connection?.referenceId
        )?.elementConfig?.config?.produtoId;
        let link = null;
        const produto = produtos.find((pro) => pro.id == produtoId);
        if (
          Array.isArray(produto?.AcessoLink) &&
          produto?.AcessoLink.length > 0
        )
          setLink(produto.AcessoLink[0]?.link);
      }
    }, [connection?.id]);

    useEffect(() => {
      // Early return se não houver itens
      if (!itens?.length) {
        setInterfaces([]);
        setInterfaceSelected(null);
        setOtherItensConnection([]);
        return;
      }

      // Remove itens duplicados baseados no itemid
      const uniqueItems = [
        ...new Map(itens.map((item) => [item.itemid, item])).values(),
      ];

      const isTrafficOrPower = (key) => {
        return (
          (key?.toLowerCase()?.includes("inoctets") ||
            key?.toLowerCase()?.replace(/_/g, "").includes("rxpower") ||
            key?.toLowerCase()?.includes("outoctets") ||
            key?.toLowerCase()?.replace(/_/g, "").includes("txpower")) &&
          !key.toLowerCase()?.includes("_olt")
        );
      };

      // Filtra e extrai interfaces dos itens
      const extractInterfaceName = (key) =>
        key?.match(/\[([^\],]+?(?=\s\-|,|\]))/)?.[1];

      const interfaces = uniqueItems
        .filter(
          (item) => item?.key_?.includes("[") && isTrafficOrPower(item.key_)
        )
        .map((item) => ({
          interface: extractInterfaceName(item.key_),
          hostid: item.hostid,
        }))
        .filter(Boolean); // Remove valores undefined

      const uniqueInterfaces = [
        ...new Map(interfaces.map((obj) => [obj.interface, obj]))?.values(),
      ];

      // Encontra a interface selecionada
      const findSelectedInterface = (items) => {
        const selectedItem = items?.find((item) => {
          const key = item.key_?.toLowerCase();
          return isTrafficOrPower(key);
        });
        if (!selectedItem) return null;
        return {
          interface: extractInterfaceName(selectedItem?.key_),
          hostid: selectedItem?.hostOriginId,
        };
      };
      const interfaceSelected = findSelectedInterface(connection?.item);

      // Filtra outros itens que não são interfaces
      const filterOtherItems = (items) => {
        return items
          .filter((item) =>
            connection?.item?.some((con) => {
              const conKey = con.key_?.toLowerCase();
              return con.itemId === item.itemid && !isTrafficOrPower(conKey);
            })
          )
          .sort((a, b) => a.name?.localeCompare(b.name));
      };
      const otherItems = filterOtherItems(uniqueItems);
      // Atualiza os estados
      if (interfaceSelected) setInterfaceSelected(interfaceSelected);
      if (uniqueInterfaces.length) setInterfaces(uniqueInterfaces);
      if (otherItems.length) setOtherItensConnection(otherItems);
    }, [itens]);

    useEffect(() => {
      if (hostId == "" || hostId == null || link == "" || !connection) return;
      getItens();
    }, [link, hostId]);

    useEffect(() => {
      if (connection === null) {
        clearMemory();
      }
    }, [connection]);

    const isTrafficOrPower = (key) => {
      return (
        (key?.toLowerCase()?.includes("inoctets") ||
          key?.toLowerCase()?.replace(/_/g, "").includes("rxpower") ||
          key?.toLowerCase()?.includes("outoctets") ||
          key?.toLowerCase()?.replace(/_/g, "").includes("txpower")) &&
        !key.toLowerCase()?.includes("_olt")
      );
    };

    function clearMemory() {
      setLoadRouting(false);
      setLine("reta");
      setItens([]);
      setLink("");
      setHostId("");
      setItemConnection([]);
      setOtherItensConnection([]);
      setCheckChanges(false);
    }
    function close() {
      setConnection(null);
      setEdgeSelected(null);
      clearMemory();
    }

    async function getItens() {
      try {
        setItemConnection([]);
        setOtherItensConnection([]);
        setLoading(true);

        const response = await api.post("/easyMaps/zabbix/itens", {
          link: link,
          hostids: hostId,
        });

        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          let responseItens = response.data;

          setItens([
            ...new Map(
              responseItens.map((item) => [item.itemid, item])
            ).values(),
          ]);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    const handleSave = () => {
      let updateConnection = connection;
      const referenceElem = elements.find(
        ({ id }) => id === updateConnection?.referenceId
      );
      const acessoProdutoId = referenceElem?.elementConfig?.config?.produtoId;
      updateConnection.item = [
        ...getItensFromInterface(interfaceSelected),
        ...otherItensConnection,
      ].map((item) => ({
        itemId: item?.itemid || null,
        itemName: item?.name || null,
        hostOriginId: item?.hostid || null,
        acessoProdutoId: acessoProdutoId || null,
        key_: item?.key_ || null,
      }));

      if (edgeSelected)
        saveConnection(
          updateConnection,
          edgeSelected,
          line,
          capacidade,
          animation,
          connection.config?.hostOriginId
        );
      else
        saveConnection(
          updateConnection,
          null,
          line,
          capacidade,
          animation,
          connection.config?.hostOriginId
        );
    };

    const filterOtherItens = () => {
      let filteredItems = itens.filter(
        (a) =>
          !a?.key_.toLowerCase()?.includes("inoctets") &&
          !a?.key_.toLowerCase()?.includes("outoctets") &&
          !a?.key_.toLowerCase()?.replace(/_/g, "")?.includes("rxpower") &&
          !a?.key_.toLowerCase()?.replace(/_/g, "")?.includes("txpower")
      );

      filteredItems.sort((a, b) => a?.name?.localeCompare(b.name));
      return [
        ...new Map(filteredItems.map((item) => [item?.itemid, item])).values(),
      ];
    };

    const getItensFromInterface = (interfaceSelected) => {
      if (!interfaceSelected) {
        return [];
      }
      // Função auxiliar para verificar se a chave contém uma substring
      const keyContains = (key, substring) =>
        key?.toLowerCase()?.includes(substring?.toLowerCase());

      // Encontrar os itens de tráfego e potência correspondentes
      const traffic = itens.filter(
        (item) =>
          keyContains(item?.key_, `octets`) &&
          (keyContains(item?.key_, interfaceSelected + "]") ||
            keyContains(item?.key_, interfaceSelected + " ") ||
            keyContains(item?.key_, interfaceSelected + ","))
      );
      //Encotrar os itens de potência correspondentes
      const power = itens.filter(
        (item) =>
          (keyContains(item?.key_.replace(/_/g, ""), `rxpower`) ||
            keyContains(item?.key_.replace(/_/g, ""), `txpower`)) &&
          (keyContains(item?.key_, interfaceSelected + "]") ||
            keyContains(item?.key_, interfaceSelected + " ") ||
            keyContains(item?.key_, interfaceSelected + ",")) &&
          !item.key_.includes("olt")
      );
      // se contiver lane ou ramos, pegar apenas o priemiro item
      if (power.find((a) => /Lane|Ramo/.test(a.key_))) {
        power = power.filter(
          (a) => /Lane 1|Ramo 1/.test(a.key_) || !/Lane|Ramo/.test(a.key_)
        );
      }
      return [...traffic, ...power];
    };

    return (
      <Drawer
        key={connection?.id}
        variant="persistent"
        anchor={"right"}
        open={!!connection}
        //onClose={() => close()}
        sx={styles.drawer}
      >
        <Paper sx={styles.header}>
          <Stack width="100%" alignItems="center" justifyContent={"center"}>
            <IconTransform
              img={iconsElementPair.length > 0 ? iconsElementPair[0]?.img : ""}
              size="35px"
            />
            <Tooltip title={elementsPair[0]?.titulo}>
              <Typography
                sx={{
                  fontSize: "14px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {elementsPair[0]?.titulo.length > 30
                  ? elementsPair[0]?.titulo.substring(0, 30) + "..."
                  : elementsPair[0]?.titulo}
              </Typography>
            </Tooltip>
          </Stack>

          <DoubleArrowOutlined color="primary" sx={{ fontSize: "30px" }} />

          {/* <SvgArrow style={{ width: "200px" }} /> */}
          <Stack width="100%" alignItems="center" justifyContent={"center"}>
            <IconTransform
              img={
                iconsElementPair.length > 0
                  ? iconsElementPair[1]?.img ?? iconsElementPair[0]?.img
                  : ""
              }
              size="35px"
            />
            <Tooltip title={elementsPair[1]?.titulo}>
              <Typography
                sx={{
                  fontSize: "14px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {elementsPair[1]?.titulo.length > 30
                  ? elementsPair[1]?.titulo.substring(0, 30) + "..."
                  : elementsPair[1]?.titulo}
              </Typography>
            </Tooltip>
          </Stack>
        </Paper>

        {!!loading && (
          <Stack
            gap={2}
            sx={{
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <LinearProgress />
            </Stack>
          </Stack>
        )}
        {!loading && (
          <>
            <Stack sx={styles.container}>
              <Stack direction="row" gap={2} alignItems="center">
                <LinkedIcon
                  style={{
                    fill: theme.palette.primary.main,
                    height: "24px",
                    width: "24px",
                  }}
                />
                <Typography sx={styles.text}>
                  Interface do host de origem
                </Typography>
              </Stack>
              <Autocomplete
                disableCloseOnSelect
                key="interfaceAutocomplete"
                id="interfaceHost"
                size="small"
                color="primary"
                options={interfaces}
                getOptionLabel={(option) => {
                  if (Array.isArray(option) || !option) return {};
                  return option.interface;
                }}
                isOptionEqualToValue={(option, value) =>
                  option.interface == value.interface
                }
                ListboxComponent={VirtualizedListbox}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "0px" }} key={option.itemid}>
                    <Tooltip
                      title={option.interface}
                      placement="left-start"
                      arrow
                    >
                      <Stack sx={styles.stackOption}>
                        <Checkbox checked={selected} />
                        <span style={styles.textoLimitado}>
                          {option.interface}
                        </span>
                      </Stack>{" "}
                    </Tooltip>
                  </li>
                )}
                value={interfaceSelected || null}
                onChange={(event, newValue) => {
                  setInterfaceSelected(newValue);
                  setCheckChanges(true);
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={params.inputProps.value}
                    placement="left"
                    arrow
                  >
                    <TextField
                      key="interfaceText"
                      {...params}
                      sx={styles.textField}
                      color="primary"
                      placeholder="Interface"
                      helperText={
                        itens?.length == 0 && "Esse elemento não contém itens"
                      }
                    />
                  </Tooltip>
                )}
              />
              <Stack direction="row" gap={2} alignItems="center">
                <LinkedIcon
                  style={{
                    fill: theme.palette.primary.main,
                    height: "24px",
                    width: "24px",
                  }}
                />
                <Typography sx={styles.text}>Adicione outros itens</Typography>
              </Stack>

              <Autocomplete
                key="autoc-02"
                multiple
                disableCloseOnSelect
                disablePortal
                id="itensnoTraffic"
                size="small"
                color="primary"
                options={filterOtherItens()}
                value={[...otherItensConnection] || []}
                onChange={(event, newValue) => {
                  setOtherItensConnection(newValue);
                  setCheckChanges(true);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Tooltip title={option.name} key={index + "tool"}>
                      <Chip label={option.name} {...getTagProps({ index })} />
                    </Tooltip>
                  ))
                }
                ListboxComponent={VirtualizedListbox}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "0px" }} key={option.itemid}>
                    <Tooltip title={option.name} placement="left-start">
                      <Stack sx={styles.stackOption}>
                        <Checkbox checked={selected} />
                        <span style={styles.textoLimitado}>{option.name}</span>
                      </Stack>{" "}
                    </Tooltip>
                  </li>
                )}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) =>
                  option?.itemid == value?.itemid
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="primary"
                    placeholder="Outros itens"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        startAdornment: (
                          <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <InputAdornment position="start">
                              <ViewInArOutlined
                                sx={{
                                  color: theme.palette.color.bgIcon,
                                  fontSize: "20px",
                                }}
                              />
                            </InputAdornment>
                            <Stack
                              sx={{
                                maxWidth: "200px",
                              }}
                            >
                              {params.InputProps.startAdornment}
                            </Stack>
                          </Stack>
                        ),
                      },
                    }}
                  />
                )}
              />
              <Stack direction="row" gap={2} alignItems="center">
                <AnimationOutlined color="primary" />
                <Typography sx={styles.text}>Selecione a animação</Typography>
              </Stack>

              <TextField
                select
                size="small"
                value={animation}
                onChange={(event) => {
                  setCheckChanges(true);
                  setAnimation(event.target.value);
                }}
                placeholder="animação"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <LineStyle
                          sx={{
                            ml: "-10px",
                            color: theme.palette.color.bgIcon,
                            fontSize: "22px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              >
                <MenuItem value="desativada">
                  <em>Desativada</em>
                </MenuItem>
                {animationTypes?.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              {tipoMapa == 1 && (
                <>
                  <Stack direction="row" gap={2} alignItems="center">
                    <ShapeLineOutlined color="primary" />
                    <Typography sx={styles.text}>
                      Selecione o tipo de Linha
                    </Typography>
                  </Stack>

                  <TextField
                    select
                    size="small"
                    value={line}
                    onChange={(event) => {
                      setLine(event.target.value);
                      changeConnection(connection.id, {
                        type: event.target.value,
                      });
                      setCheckChanges(true);
                    }}
                    placeholder="Tipo de linha"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Timeline
                              sx={{
                                ml: "-10px",
                                color: theme.palette.color.bgIcon,
                                fontSize: "22px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      },
                    }}
                  >
                    {lineTypes?.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {/* <Camera /> */}
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
              <Stack direction="row" gap={2} alignItems="center">
                <CapacityIcon
                  color="primary"
                  sx={{
                    fontSize: "30px",
                  }}
                />
                <Typography sx={styles.text} ml="-5px">
                  Informe a capacidade máxima (Gb)
                </Typography>
              </Stack>
              <TextField
                size="small"
                value={capacidade}
                onChange={(e) => {
                  setCapacidade(e.target.value);
                  setCheckChanges(true);
                }}
                //helperText="Gb"
              />
              {tipoMapa == 0 && (
                <>
                  <Stack direction="row" gap={2} alignItems="center">
                    <ForkRightIcon color="primary" sx={{ fontSize: "30px" }} />
                    <Typography sx={styles.text}>
                      Roteamento automático
                    </Typography>
                  </Stack>
                  <Stack gap={1} direction={"row"}>
                    <Button
                      loading={loading}
                      sx={styles.buttonRoter}
                      disabled={loadRouting}
                      onClick={() => {
                        setCheckChanges(true);
                        autoRouting(connection);
                      }}
                    >
                      {!loadRouting ? (
                        <>
                          <NearMeIcon />
                          <Typography sx={styles.textButtonRoter}>
                            Encontrar rota
                          </Typography>
                        </>
                      ) : (
                        <CircularProgress size={30} color="primary" />
                      )}
                    </Button>
                    <Button
                      sx={styles.buttonRoter}
                      disabled={loadRouting}
                      onClick={() => {
                        setCheckChanges(true);
                        cleanRouting(connection);
                      }}
                    >
                      <CleaningServicesOutlinedIcon />
                      <Typography sx={styles.textButtonRoter}>
                        Limpar Pontos
                      </Typography>
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              gap={2}
              mt="-10px"
            >
              {connection?.id && (
                <Button
                  sx={styles.buttonBottom}
                  color="primary"
                  variant="text"
                  //endIcon={<DeleteOutline />}
                  startIcon={<DeleteOutline />}
                  onClick={() => {
                    setCheckChanges(false);
                    handleDelete(connection.id);
                  }}
                >
                  {"Remover"}
                </Button>
              )}
              <Button
                sx={styles.buttonBottom}
                color="primary"
                variant="text"
                startIcon={<CancelOutlined />}
                // endIcon={<CloseOutlined />}
                onClick={() => {
                  checkChanges
                    ? setOpenAlertModal({
                        open: true,
                        severity: "warning",
                        text: "As alterações feitas não foram salvas. Se prosseguir com a operação as mudanças serão perdidas.",
                      })
                    : close();
                }}
              >
                {"Cancelar"}
              </Button>

              <LoadingButton
                sx={[
                  styles.buttonBottom,
                  {
                    color: theme.palette.text.primary,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    },
                  },
                ]}
                color="primary"
                variant="text"
                loading={loadingButton}
                //disabled={!checkChanges}
                startIcon={<SaveOutlined />}
                onClick={() => {
                  handleSave();
                  close();
                }}
              >
                {connection?.id ? "Salvar" : "Adicionar"}
              </LoadingButton>
            </Stack>
          </>
        )}
      </Drawer>
    );
  }
);
export default EnlaceSideBarEasyMaps;
