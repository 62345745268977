import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment } from "react";

const MaisDetalhesDefault = ({ conexao, theme, styles, formatString }) => {
  return (
    Object.keys(conexao?.data?.itens || []).length > 0 && (
      <Accordion
        key={"outrosItensElemnteSeleted"}
        disableGutters
        sx={{
          backgroundColor: theme.palette.background.paperLinkedMaps,
          boxShadow: "none",
          "&::before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh"
        >
          <Typography sx={styles.textHeaderAco}>Outros itens</Typography>
        </AccordionSummary>

        <AccordionDetails>
          {(conexao?.data?.itens || []).map((nick, index) => (
            <Fragment key={"dadosCliente" + index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                width="100%"
              >
                <Typography
                  sx={styles.textAlerta}
                  fontSize={"0.7rem"}
                  width="170px"
                >
                  {formatString(nick.name)}
                </Typography>

                <Stack direction={"row"} gap={"2px"}>
                  <>
                    <Typography
                      textAlign={"end"}
                      sx={{
                        ...styles.textHeaderAco,
                        textTransform: "lowercase",
                      }}
                    >
                      {nick?.lastValue}
                    </Typography>
                    <Typography
                      sx={{
                        ...styles.textHeaderAco,
                        textTransform: "lowercase",
                        color: theme.palette.primary.main,
                      }}
                      variant="small"
                    >
                      {nick?.units}
                    </Typography>
                  </>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </Fragment>
          ))}{" "}
        </AccordionDetails>
      </Accordion>
    )
  );
};
export default MaisDetalhesDefault;
