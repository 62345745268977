import { Box, CircularProgress, Icon } from "@mui/material";

import mwIcon from "../../assets/logo4.png";

export default function LoadingPage(props) {
  const { mt = "20%" } = props;
  const logo = `${window?.config?.REACT_APP_LOGO_SMALL_URL || mwIcon}`;

  const styles = {
    container: {
      position: "relative",
      m: 1,
      width: "100%",
      marginTop: mt,
      display: "flex",
      justifyContent: "center",
    },
    body: {
      position: "relative",
    },
    icon: {
      width: "80px",
      height: "auto",
    },
    img: {
      width: "80px",
      height: "auto",
    },
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Icon style={styles.icon}>
          <img src={logo} style={styles.img} />
        </Icon>
        <CircularProgress
          size={100}
          sx={{
            color: "primary",
            position: "absolute",
            top: -9,
            left: -6,
            zIndex: 1,
          }}
          thickness={2.5}
        />
      </Box>
    </Box>
  );
}
