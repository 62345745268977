import React, { useState } from "react";

import {
  Alert,
  Box,
  CssBaseline,
  Link,
  Snackbar,
  Typography,
  createSvgIcon,
} from "@mui/material";

import { ReactComponent as IconFlow } from "../../assets/icons/IconEasyFlow.svg";
import { ReactComponent as IconReport } from "../../assets/icons/IconEasyReport.svg";
import { ReactComponent as IconMaps } from "../../assets/icons/IconEasyMaps.svg";
import { ReactComponent as IconLogo } from "../../assets/logosSvg/logoMW.svg";
import { Outlet } from "react-router-dom";
import {
  Home,
  PlaylistAdd,
  RouterOutlined,
  SupportAgent,
} from "@mui/icons-material";
//import LoadingPage from "../../componentes/loadingPage";
import TopBar from "../../componentes/topBar";
import SideBar from "../../componentes/sideBar";

const EasyFlowIcon = createSvgIcon(<IconFlow />);
const EasyReportIcon = createSvgIcon(<IconReport />);
const EasyMapsIcon = createSvgIcon(<IconMaps />);

function Layout(params) {
  const { colorMode, setColorMode, socket, socketCliente } = params;

  const [snackbar, setSnackbar] = useState(null);
  const [openRightBar, setOpenRightBar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [titlePage, setTitlePage] = useState("");
  const [link, setLink] = useState("");

  const clientLogo = `${window?.config?.REACT_APP_LOGO_URL || ""}`;
  const isPartner = window?.config?.REACT_APP_IS_PARTNER === "true" || false;

  const styles = {
    containerLayout: {
      display: "flex",
      width: "100%",
      height: "fit-content",
      flexDirection: "column",
    },
    containerBody: {
      display: "flex",
      width: "100%",
      height: "100%",
      minWidth: "350px",
    },
    containerOutlet: {
      flexGrow: 1,
      padding: 3,
      // paddingTop: 5,
      width: "100%",
      gap: 2,
      minWidth: "calc(100vw - 300px)",
    },
    titleAndLinkPage: {
      flexGrow: 1,
      fontWeight: 400,
      fontSize: "1.25rem",
      marginX: 1,
      mt: 1,
      mb: 2,
    },
  };
  const rightBarPages = [
    {
      itemId: "1",
      label: "Home",
      link: "/homeIn",
      hideTitle: true,
      icon: <Home />,
    },
    {
      itemId: "2",
      label: "Adição de Host",
      link: "/easyAdd",
      icon: <PlaylistAdd />,
    },
    ...(!isPartner
      ? [
          {
            itemId: "3",
            label: "Detalhes de ONU",
            link: "/scriptONUs",
            icon: <RouterOutlined />,
          },
          {
            itemId: "4",
            label: "Suporte",
            link: "/suporte",
            icon: <SupportAgent />,
          },
          {
            itemId: "5",
            label: "EasyFlow",
            link: "/easyFlow",
            icon: <EasyFlowIcon />,
            nodes: [
              {
                itemId: "5.1",
                label: "Relatórios Flow",
                link: "/easyFlow/relatoriosFlow",
              },
            ],
          },
        ]
      : []),
    // {
    //   itemId: "4",
    //   label: "Documentação",
    //   link: "https://wiki.mw-solucoes.com",
    //   icon: <Description />,
    // },

    {
      itemId: "6",
      label: isPartner ? "Report" : "EasyReport",
      link: "/easyReport",
      hideTitle: true,
      icon: <EasyReportIcon />,
    },
    {
      itemId: "7",
      label: isPartner ? "Mapas" : "EasyMaps",
      link: "/easyMaps",
      icon: <EasyMapsIcon />,
      openInNewTab: true,
    },
  ];
  return (
    <Box display={"flex"}>
      <CssBaseline />
      <Box sx={styles.containerLayout}>
        <TopBar
          setOpenDrawer={setOpenDrawer}
          setOpenRightBar={setOpenRightBar}
          iconLogo={<IconLogo />}
          clientLogo={clientLogo}
          link={"/homeIn"}
          socket={socket}
          socketCliente={socketCliente}
          colorMode={colorMode}
          setColorMode={setColorMode}
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          openRightBar={openRightBar}
        />
        <Box sx={styles.containerBody}>
          <SideBar
            items={rightBarPages}
            title="Navegação"
            openRightBar={openRightBar}
            setOpenRightBar={setOpenRightBar}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            iconLogo={<IconLogo />}
            clientLogo={clientLogo}
            titlePage={titlePage}
            setTitlePage={setTitlePage}
            link={link}
            setLink={setLink}
          />
          <Box component="main" sx={styles.containerOutlet}>
            {/* <Link
              href={link}
              color="text.secondary"
              sx={styles.titleAndLinkPage}
            >
              {titlePage}
            </Link> */}
            {titlePage && (
              <Typography
                color="text.primary"
                variant="h5"
                sx={styles.titleAndLinkPage}
              >
                {titlePage}
              </Typography>
            )}
            <Outlet context={{ setTitlePage, socketCliente }} />
          </Box>
        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}

export default Layout;
