import { alpha, darken, lighten, useMediaQuery } from "@mui/material";

const useStyles = (theme, elementSelected) => ({
  button: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    bottom:
      useMediaQuery(theme.breakpoints.down("mdl")) || elementSelected
        ? "40px"
        : "15px",
    left: "15px",
    zIndex: 1,
    width: "60px",
    height: "60px",
    minWidth: "0px",

    backgroundColor: theme.palette.background.paperSelectedMaps,
    border: "1px solid " + theme.palette.color.appBarBorder,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    p: "5px",

    boxShadow: "0px 0px 3px  #0000001a",
    backgroundColor: theme.palette.background.default,
    border: "1px solid " + theme.palette.color.appBarBorder,
    borderRadius: "5px",
  },
});
export default useStyles;
