import { ExpandMore, ReportProblem, Straight } from "@mui/icons-material";
import {
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

const AccordionSummaryFtth = ({
  conexao,
  colorAlert,
  index,
  expanded,
  unique,
  theme,
}) => {
  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },
    text: {
      fontSize: "0.85em",
      width: "100%",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
      textAlign: "left",
    },
  };
  return (
    <AccordionSummary
      expandIcon={!unique ? <ExpandMore /> : null}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      sx={{
        padding: "0px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        ml={1}
      >
        {/* {expanded != index && !unique && ( */}
        <Box sx={[styles.line, { backgroundColor: colorAlert }]} />
        {/* )} */}

        <Stack direction={"column"} gap={1} ml={1}>
          <>
            <Tooltip title={conexao.elementOriginName}>
              <Typography sx={styles.text}>
                {conexao.elementOriginName}
              </Typography>
            </Tooltip>
            <Divider width={"80px"} />

            <Tooltip title={conexao.titulo}>
              <Typography sx={styles.text}>{conexao.titulo}</Typography>
            </Tooltip>
          </>
        </Stack>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          {conexao.isValid == false && (expanded != index || unique) && (
            <Tooltip title={"ONU sem coordenadas"}>
              <ReportProblem
                sx={{
                  fontSize: "20px",
                  mr: 0,
                  color: theme.palette.color.alerts.warning,
                }}
              />
            </Tooltip>
          )}
          <Straight
            sx={{
              fontSize: "30px",
              transform: true ? "rotate(180deg)" : null,
              mr: 0,
              color: theme.palette.mode == "dark" ? "#565656" : "#9A9A9A",
            }}
          />
        </Stack>
      </Stack>
    </AccordionSummary>
  );
};
export default AccordionSummaryFtth;
