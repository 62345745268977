import { alpha, createTheme } from "@mui/material/styles";

const colors = {
  primary: {
    light: "#5B2E87",
    dark: "#A77AD2",
  },
  secondary: {
    light: "#e83750",
    dark: "#e83750",
  },
};

const mainTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? colors.primary.light : colors.primary.dark,
      },
      secondary: {
        main: !colorMode ? colors.secondary.light : colors.secondary.dark,
      },
      drawer: {
        main: !colorMode ? "#5B2E87" : "#5B2E87",
        text: !colorMode ? "#E8E6E3" : "#ffffff",
        chat: !colorMode ? "#fff " : "#000000",
      },
      background: {
        default: !colorMode ? "#F0F2F5" : "#141617",
        paper: !colorMode ? "#FFFFFF" : "#1e1e1e",
        card: !colorMode ? "#FFFFFF" : "#272727",
        hover: !colorMode ? alpha("#F0F2F5", 0.3) : alpha("#141617"),
        // default: !colorMode ? "#dbdcdf" : "#070707",
        // paper: !colorMode ? "#fff " : "#3b2626",
        paperOutlined: !colorMode ? "#fff " : "#252525",
        paperChat: !colorMode ? "#F9FAFB" : "#353535ad",
        paperNotification: !colorMode ? "#e4e3e3" : "#1d2021",
        textField: !colorMode ? "#E3E3E3 " : "#2F2F2F",
      },
      layoutBackGround: { main: !colorMode ? "#5B2E87" : "#121212" },
      text: {
        primary: !colorMode ? "#3C3C3C" : "#ECECEC",
        secondary: !colorMode ? "#757575" : "#9A9A9A",
        disabled: !colorMode ? "#9E9E9E" : "#878686",
      },
      sucess: {
        main: !colorMode ? "#04B55D" : "#1ACB73",
      },
      warning: {
        main: !colorMode ? "#FFBB00" : "#FDC52B",
      },
      error: {
        main: !colorMode ? "#FF4130" : "#FF5343",
      },
      info: {
        main: !colorMode ? "#0A80FF" : "#3F9DF4",
      },
      access: {
        admin: !colorMode ? "#FB8C00" : "#f5a23d",
        usuario: !colorMode ? "#42A5F5" : "#5fb0f1",
        superAdmin: !colorMode ? "#8c38c0" : "#c297eb",
      },
      chip: {
        pending: !colorMode ? "#E83750" : "#A61328",
        warning: !colorMode ? "#e89b37" : "#c28a37",
        inProgress: !colorMode ? "#ED6C02" : "#BE5602",
        completed: !colorMode ? "#2E7D32" : "#387F4D",
        waitingReview: !colorMode ? "#0288D1" : "#077CB1",
        waitingReturn: !colorMode ? "#5B2E87" : "#502876",
        arquive: !colorMode ? "#777777" : "#424242",
      },
      color: {
        icons: colorMode ? "#fff " : "#313131",
        textDisabled: colorMode ? "#969696" : "#676767",
      },
      stroke: { main: !colorMode ? "#D8D8D8" : "#3A3A3A" },
    },
    breakpoints: {
      values: {
        xs: 0,
        xsm: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        fh: 1920,
        qhd: 2560,
      },
    },

    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "smoth" },
            style: {
              backgroundColor: !colorMode
                ? alpha(colors.primary.light, 0.1)
                : alpha(colors.primary.dark, 0.1),
              color: !colorMode ? colors.primary.light : colors.primary.dark,
            },
          },
        ],
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            boxShadow: "none",
            borderRadius: "5px",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          containedPrimary: true && { backgroundColor: "#5B2E87" },
        },
      },
      // MuiDrawer: {
      //   styleOverrides: {
      //     paper: {
      //       backgroundColor: !colorMode ? "#5B2E87" : "#121212",
      //       color: "#fff",
      //     },
      //   },
      // },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: !colorMode ? "#5B2E87" : "#5B2E87",
          },
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "default" }, // Define a nova variante
            style: {
              border: "none",
              boxShadow: "0px 1px 2px  #0000001a",
            },
          },
          {
            props: { variant: "brick" },
            style: {
              display: "flex",
              border: "none",
              boxShadow: "0px 0px 4px 0px  rgba(0, 0, 0, 0.25)",
              padding: "6px",
            },
          },
        ],
        defaultProps: {
          variant: "default", // Define a variante padrão como outlined
        },
        styleOverrides: {
          root: {
            borderRadius: "5px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& ::placeholder": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputLabel-root": {
              fontSize: "1rem", // Tamanho do texto do label
              marginBottom: "5px",
              //display: "none",
            },
            "& .MuiInputBase-input": {
              fontSize: "1rem", // Tamanho do texto do placeholder
              display: "flex",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              boxShadow: "0px 0px 2px  #0000001a",
              backgroundColor: theme.palette.background.textField,

              "&.Mui-focused": {
                backgroundColor: "none",
                "& fieldset": {
                  border: `1px solid  ${theme.palette.text.disabled}`,
                },
                "&:hover": {
                  "& fieldset": {
                    border: `1px solid  ${theme.palette.text.disabled}`,
                  },
                },
              },

              "&:hover": {
                backgroundColor: theme.palette.background.textField,
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
              "&.Mui-disabled": {
                backgroundColor: alpha(theme.palette.background.paper, 0.2),
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover": {
                  "& fieldset": {
                    borderColor: "none",
                  },
                },
              },
            },
          }),
        },
        defaultProps: {
          //variant: "contained",
          size: "small", // Define a variante padrão como outlined
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& button": {
              backgroundColor: theme.palette.background.textField,
              color: theme.palette.text.secondary,
              textTransform: "none",
              paddingBottom: "30px",
              borderRadius: "4px",
              minHeight: "70px",
            },
            "& .MuiTabs-flexContainer": {
              height: "42px",
            },
            "& button.Mui-selected": {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
            "& .MuiTabs-indicator": {
              //backgroundColor: theme.palette.background.paper,
              display: "none",
            },
          }),
        },
      },
    },
    mixins: {
      MuiDataGrid: {
        containerBackground: !colorMode ? "#fff" : "#1E1E1E",
      },
    },
    global: `
      .leaflet-layer,
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out,
      .leaflet-control-attribution {
        filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
      }
    `,
  });

export default mainTheme;
