import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Slider,
  Snackbar,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import useStyles from "./styles";
import { useEffect, useState } from "react";
import api from "../../../services/api";

import {
  CancelOutlined,
  Check,
  CloseOutlined,
  ImportExport,
  InfoOutlined,
  MapOutlined,
  PublicOutlined,
  SignalCellularAlt,
  SignalCellularAlt1BarSharp,
  Undo,
  MultipleStop,
  WifiFindOutlined,
} from "@mui/icons-material";
import Escala from "./escala";
import AlertModal from "../../alertModal";
import Alerts from "../alerts";

export default function ConfiguracaoEasyMapsModal({
  open,
  setOpen = () => {},
  config,
  setConfig = () => {},
  setConfigLocal = () => {},
  tabDefault = 0,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [snackbar, setSnackbar] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [openAlertModal, setOpenAlertModal] = useState(null);
  const [checkChanges, setCheckChanges] = useState(false);
  const [linkFTTHVerification, setLinkFTTHVerification] = useState(true);
  // página geral
  const [linkLight, setLinkLight] = useState(null);
  const [linkDark, setLinkDark] = useState(null);
  const [mapCustom, setMapCustom] = useState(false);
  const [limiar, setLimiar] = useState(null);
  const [animacaoLinha, setAnimacaoLinha] = useState(true);
  const [animacaoAlertas, setAnimacaoAlertas] = useState(true);
  //pagina de tráfego
  const [escalaTrafego, setEscalaTrafego] = useState([]);
  const [tempoColetaTrafego, setTempoColetaTrafego] = useState(0);
  const [valorTempoTrafego, setValorTempoTrafego] = useState(0);
  const [unidadeTempoTrafego, setUnidadeTempoTrafego] = useState(0);
  //pagina de potencia
  const [escalaPotencia, setEscalaPotencia] = useState([]);
  const [tempoColetaPotencia, setTempoColetaPotencia] = useState(0);
  const [valorTempoPotencia, setValorTempoPotencia] = useState(0);
  const [unidadeTempoPotencia, setUnidadeTempoPotencia] = useState(0);
  //pagina de ftth
  const [escalaFtth, setEscalaFtth] = useState([]);
  const [tempoColetaFtth, setTempoColetaFtth] = useState(0);
  const [valorTempoFtth, setValorTempoFtth] = useState(0);
  const [unidadeTempoFtth, setUnidadeTempoFtth] = useState(0);
  const [linkFtth, setLinkFtth] = useState(null);

  const listTabs = [
    { label: "Mapa de FTTH", icon: <WifiFindOutlined />, value: 0 },

    {
      label: "Camada de tráfego",
      icon: <MultipleStop />,
      value: 1,
    },
    //{ label: "Camada de potência", icon: <SignalCellularAlt />, value: 2 },
    {
      label: "Outros",
      icon: <PublicOutlined />,
      value: 3,
    },
  ];
  const marks = [
    { value: 0, label: "Baixa" },
    { value: 1, label: "Moderada" },
    { value: 2, label: "Boa" },
    { value: 3, label: "Excelente" },
  ];

  useEffect(() => {
    if (!valorTempoTrafego || !unidadeTempoTrafego) return;
    setTempoColetaTrafego(valorTempoTrafego * unidadeTempoTrafego);
  }, [valorTempoTrafego, unidadeTempoTrafego]);

  useEffect(() => {
    if (!valorTempoPotencia || !unidadeTempoPotencia) return;
    setTempoColetaPotencia(valorTempoPotencia * unidadeTempoPotencia);
  }, [valorTempoPotencia, unidadeTempoPotencia]);

  useEffect(() => {
    if (!valorTempoFtth || !unidadeTempoFtth) return;
    setTempoColetaFtth(valorTempoFtth * unidadeTempoFtth);
  }, [valorTempoFtth, unidadeTempoFtth]);

  function formatMilliseconds(ms) {
    if (ms < 60000 || ms % 60000 !== 0) {
      const seconds = Math.floor(ms / 1000);
      return { valor: seconds, unidade: 1000 };
    } else if (ms < 60 * 60 * 1000 || ms % (60 * 60 * 1000) !== 0) {
      const minutes = Math.floor(ms / (60 * 1000));
      return { valor: minutes, unidade: 60000 };
    } else {
      const hours = Math.floor(ms / (60 * 60 * 1000));
      return { valor: hours, unidade: 3600000 };
    }
  }

  useEffect(() => {
    if (!config || !open.open) return;
    setTabValue(tabDefault);
    setLinkDark(
      config?.mapaTile?.dark ||
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    );
    setLinkLight(
      config?.mapaTile?.light ||
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    );

    if (config?.limiarRouter) setLimiar(parseFloat(config?.limiarRouter));
    if (config?.animacao?.linha != null)
      setAnimacaoLinha(config?.animacao?.linha);
    if (config?.animacao?.alertas != null)
      setAnimacaoAlertas(config?.animacao?.alertas);

    setEscalaTrafego(config?.escalas?.trafego);
    setEscalaPotencia(config?.escalas?.potencia);

    setEscalaFtth(config?.escalas?.ftth);

    if (config?.atualizacao?.trafego != null) {
      const { valor, unidade } = formatMilliseconds(
        config?.atualizacao?.trafego
      );

      setValorTempoTrafego(valor);
      setUnidadeTempoTrafego(unidade);
    }
    if (config?.atualizacao?.potencia != null) {
      const { valor, unidade } = formatMilliseconds(
        config?.atualizacao?.potencia
      );
      setValorTempoPotencia(valor);
      setUnidadeTempoPotencia(unidade);
    }
    if (config?.atualizacao?.ftth != null) {
      const { valor, unidade } = formatMilliseconds(config?.atualizacao?.ftth);
      setValorTempoFtth(valor);
      setUnidadeTempoFtth(unidade);
    }
    if (config?.linkFTTH) setLinkFtth(config?.linkFTTH);
  }, [config, open.open, open]);

  useEffect(() => {
    if (!linkFtth || linkFtth == "") {
      setLinkFTTHVerification(true);
      return;
    }
    //verify questdb link
    if (
      //linkFtth.includes("questdb.") &&
      linkFtth.includes("https://") ||
      linkFtth.includes("http://")
    ) {
      setLinkFTTHVerification(true);
    } else {
      setLinkFTTHVerification(false);
    }
  }, [linkFtth]);

  const updatesTimes = () => {
    return (
      valorTempoFtth >= 1 && valorTempoTrafego >= 1 //&&  valorTempoPotencia >= 1
    );
  };

  const handleClose = () => {
    setLinkLight(null);
    setLinkDark(null);
    setMapCustom(null);
    setLimiar(null);
    setAnimacaoLinha(null);
    setAnimacaoAlertas(null);
    setEscalaTrafego([]);
    setEscalaPotencia([]);
    setEscalaFtth([]);
    setLinkFtth(null);
    setCheckChanges(false);
    setValorTempoFtth(0);
    setUnidadeTempoFtth(0);
    setUnidadeTempoTrafego(0);
    setValorTempoTrafego(0);
    setValorTempoPotencia(0);
    setUnidadeTempoPotencia(0);
    setTempoColetaFtth(0);
    setTempoColetaPotencia(0);
    setTempoColetaTrafego(0);
    setTabValue(0);
    setOpen({ open: false });
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  async function handleApiUpdateConfig() {
    try {
      const response = await api.put("/easyMaps/config/update", {
        id: config.id,
        data: {
          animacao: { linha: animacaoLinha, alertas: animacaoAlertas },
          atualizacao: {
            ftth: tempoColetaFtth,
            potencia: tempoColetaPotencia,
            trafego: tempoColetaTrafego,
          },
          escalas: {
            ftth: escalaFtth,
            potencia: escalaPotencia,
            trafego: escalaTrafego,
          },
          limiarRouter: parseFloat(limiar),
          linkFTTH: linkFtth.endsWith("/") ? linkFtth.slice(0, -1) : linkFtth,
          mapaTile: { dark: linkDark, light: linkLight },
        },
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Não foi possível salvar as mudanças as configurações",
          severity: "error",
        });
      } else {
        if (response.data) {
          setConfig(response.data.config);

          setConfigLocal(
            JSON.parse(
              JSON.stringify({ animacao: response?.data?.config?.animacao })
            )
          );
          handleClose();
          setSnackbar({
            children: "Configurações salvas com sucesso!",
            severity: "success",
          });
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <>
      <Modal
        open={open.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={styles.container}>
          <Stack sx={styles.containerTab}>
            <Typography sx={styles.pageTitle}>Configurações</Typography>
            <Tabs
              orientation="vertical"
              scrollButtons={true}
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
              sx={styles.tabs}
            >
              {listTabs.map((tab, index) => (
                <Tab
                  key={index + "tab"}
                  label={tab.label}
                  iconPosition="start"
                  icon={tab?.icon}
                  value={tab?.value}
                />
              ))}
            </Tabs>
          </Stack>
          <Stack sx={styles.containerData}>
            <Typography sx={{ ...styles.pageTitle, ...styles.titlePage }}>
              {listTabs[tabValue]?.label || ""}
            </Typography>

            <Stack
              gap={1.5}
              sx={{ maxHeight: "80%", overflow: "auto", pr: "5px" }}
            >
              {tabValue == listTabs.find((a) => a.label == "Outros")?.value && (
                <>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}> Links dos tiles</Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Links dos servidores que fornecem mapas de fundo para os
                        mapas com geolocalização.
                      </Typography>
                    </Stack>
                    <Stack gap={2} mt={2}>
                      <>
                        <Stack justifyContent={"center"}>
                          <TextField
                            sx={styles.textField}
                            value={linkLight}
                            onChange={(e) => {
                              setCheckChanges(true);
                              setLinkLight(e.target.value);
                            }}
                            size="small"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Typography sx={styles.textAdorment}>
                                      MODO CLARO
                                    </Typography>
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </Stack>
                        <Stack justifyContent={"center"}>
                          <TextField
                            sx={styles.textField}
                            size="small"
                            value={linkDark}
                            onChange={(e) => {
                              setCheckChanges(true);
                              setLinkDark(e.target.value);
                            }}
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Typography sx={styles.textAdorment}>
                                      MODO ESCURO
                                    </Typography>
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </Stack>
                      </>
                    </Stack>
                  </Box>

                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>
                        Precisão do roteamento automático
                      </Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        A precisão define a quantidade de pontos gerados durante
                        o roteamento automático. Quanto maior for a precisão,
                        mais pontos serão gerados e mais processamento será
                        necessário.
                      </Typography>
                    </Stack>

                    <Stack
                      justifyContent={"center"}
                      mt={2}
                      sx={{ width: "98%", p: "0px 19px" }}
                    >
                      <Slider
                        aria-label="Restricted values"
                        value={limiar}
                        onChange={(e) => {
                          setCheckChanges(true);
                          setLimiar(e.target.value);
                        }}
                        min={0}
                        max={3}
                        step={null}
                        marks={marks}
                        valueLabelDisplay="off"
                        getAriaValueText={valuetext}
                        size="medium"
                      />
                    </Stack>
                    {limiar == 3 && (
                      <Stack sx={{ mt: 1 }}>
                        <Alerts
                          title={"Alerta de Desempenho "}
                          message="Esta configuração irá usar mais recursos do sistema, o que pode afetar o desempenho dependendo da configuração do seu hardware."
                          severity="warning"
                        />
                      </Stack>
                    )}
                  </Box>

                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>Animações</Typography>

                      <Typography sx={styles.textFine}>
                        Habilite ou desabilite as animações nas linhas ou nos
                        ícones em todos os mapas
                      </Typography>
                    </Stack>

                    <Stack
                      justifyContent={"center"}
                      alignItems={"start"}
                      mt={2}
                      gap={1}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={animacaoAlertas}
                            onChange={(e) => {
                              setCheckChanges(true);
                              setAnimacaoAlertas(e.target.checked);
                            }}
                          />
                        }
                        sx={styles.switch}
                        labelPlacement="start"
                        label={
                          <Typography sx={[styles.textAdorment, { ml: 0.3 }]}>
                            ANIMAÇÃO DE ALERTAS
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={animacaoLinha}
                            onChange={(e) => {
                              setCheckChanges(true);
                              setAnimacaoLinha(e.target.checked);
                            }}
                          />
                        }
                        sx={styles.switch}
                        labelPlacement="start"
                        label={
                          <Typography sx={[styles.textAdorment, { ml: 0.3 }]}>
                            ANIMAÇÃO DE LINHAS
                          </Typography>
                        }
                      />
                    </Stack>
                  </Box>
                </>
              )}
              {tabValue ==
                listTabs.find((a) => a.label == "Camada de tráfego")?.value && (
                <>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>Escalas</Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina as escalas para as camadas de tráfego clicando
                        nas cores. Elas serão ordenadas automaticamente com base
                        nos valores.
                      </Typography>
                    </Stack>

                    <Escala
                      variant="traffic"
                      escala={JSON.parse(JSON.stringify(escalaTrafego))}
                      setEscala={setEscalaTrafego}
                      setCheckChanges={setCheckChanges}
                    />
                  </Box>

                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>
                        Tempo de atualização
                      </Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina o tempo de atualização dos dados de tráfego.
                      </Typography>
                    </Stack>
                    <Stack gap={2} mt={2} direction={"row"}>
                      <Stack
                        justifyContent={"center"}
                        direction={"row"}
                        gap={1}
                      >
                        <TextField
                          onChange={(e) => {
                            setCheckChanges(true);

                            setValorTempoTrafego(
                              e.target.value >= 0 && e.target.value < 60
                                ? e.target.value
                                : e.target.value < 0
                                ? 1
                                : 59
                            );
                          }}
                          error={valorTempoTrafego < 1}
                          type="number"
                          value={
                            valorTempoTrafego != null ? valorTempoTrafego : 1
                          }
                          sx={[styles.textField, { width: "100px" }]}
                          size="small"
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography sx={styles.textAdorment}>
                                    TEMPO
                                  </Typography>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />

                        <TextField
                          select
                          onChange={(e) => {
                            setCheckChanges(true);
                            setUnidadeTempoTrafego(e.target.value);
                          }}
                          value={
                            unidadeTempoTrafego ? unidadeTempoTrafego : 60000
                          }
                          sx={[styles.textField, { width: "fit-content" }]}
                          size="small"
                        >
                          {/* <MenuItem value={1000}>segundo(s)</MenuItem> */}
                          <MenuItem value={60000}>minuto(s)</MenuItem>
                          <MenuItem value={3600000}>hora(s)</MenuItem>
                        </TextField>
                      </Stack>
                    </Stack>{" "}
                    {valorTempoTrafego < 1 && (
                      <Alerts
                        sx={{ mt: 1 }}
                        severity="error"
                        message="Tempo de coleta invalido!"
                      ></Alerts>
                    )}
                  </Box>
                </>
              )}
              {tabValue ==
                listTabs?.find((a) => a.label == "Camada de potência")
                  ?.value && (
                <>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>Escalas</Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina as escalas para as camadas de potência clicando
                        nas cores. Elas serão ordenadas automaticamente com base
                        nos valores.
                      </Typography>
                    </Stack>
                    <Escala
                      variant="power"
                      escala={JSON.parse(JSON.stringify(escalaPotencia))}
                      setEscala={setEscalaPotencia}
                      setCheckChanges={setCheckChanges}
                    />
                  </Box>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>
                        Tempo de atualização
                      </Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina o tempo de atualização dos dados de potência.
                      </Typography>
                    </Stack>
                    <Stack gap={2} mt={2} direction={"row"}>
                      <Stack
                        justifyContent={"center"}
                        direction={"row"}
                        gap={1}
                      >
                        <TextField
                          onChange={(e) => {
                            setCheckChanges(true);
                            setValorTempoPotencia(
                              e.target.value >= 0 && e.target.value < 60
                                ? e.target.value
                                : e.target.value < 0
                                ? 1
                                : 59
                            );
                          }}
                          error={valorTempoPotencia < 1}
                          value={
                            valorTempoPotencia != null ? valorTempoPotencia : 1
                          }
                          sx={[styles.textField, { width: "100px" }]}
                          size="small"
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography sx={styles.textAdorment}>
                                    TEMPO
                                  </Typography>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />

                        <TextField
                          select
                          onChange={(e) => {
                            setCheckChanges(true);
                            setUnidadeTempoPotencia(e.target.value);
                          }}
                          value={
                            unidadeTempoPotencia ? unidadeTempoPotencia : 60000
                          }
                          sx={[styles.textField, { width: "fit-content" }]}
                          size="small"
                        >
                          {/* <MenuItem value={1000}>segundo(s)</MenuItem> */}
                          <MenuItem value={60000}>minuto(s)</MenuItem>
                          <MenuItem value={3600000}>hora(s)</MenuItem>
                        </TextField>
                      </Stack>
                    </Stack>
                    {valorTempoPotencia < 1 && (
                      <Alerts
                        sx={{ mt: 1 }}
                        severity="error"
                        message="Tempo de coleta invalido!"
                      ></Alerts>
                    )}
                  </Box>
                </>
              )}
              {tabValue ==
                listTabs.find((a) => a.label == "Mapa de FTTH")?.value && (
                <>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>Banco</Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Informe o link do banco de FTTH para gerar o mapa
                        automaticamente.
                      </Typography>
                    </Stack>
                    <Stack gap={2} mt={2}>
                      <Stack justifyContent={"center"}>
                        <TextField
                          sx={styles.textField}
                          onChange={(e) => {
                            setCheckChanges(true);
                            setLinkFtth(e.target.value);
                          }}
                          value={linkFtth}
                          size="small"
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography sx={styles.textAdorment}>
                                    LINK
                                  </Typography>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                        {!linkFTTHVerification && (
                          <Alerts
                            sx={{ mt: 1 }}
                            severity="warning"
                            title="Link Invalido!"
                            message="Entre em contato com o suporte para obter ajuda!"
                          />
                        )}
                        {(linkFtth == "" || !linkFtth) &&
                          linkFTTHVerification && (
                            <Alerts
                              sx={{ mt: 1 }}
                              severity="info"
                              title="Ainda não possui um banco configurado?"
                              message="Entre em contato com o suporte para obter ajuda!"
                            />
                          )}
                      </Stack>
                    </Stack>
                  </Box>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>Escalas</Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina as escalas para os maps de FTTH clicando nas
                        cores. Elas serão ordenadas automaticamente com base nos
                        valores.
                      </Typography>
                    </Stack>

                    <Escala
                      variant="FTTH"
                      escala={JSON.parse(JSON.stringify(escalaFtth))}
                      setEscala={setEscalaFtth}
                      setCheckChanges={setCheckChanges}
                    />
                  </Box>
                  <Box sx={styles.boxOP}>
                    <Stack direction="column">
                      <Typography sx={styles.text}>
                        Tempo de atualização
                      </Typography>

                      <Typography sx={{ ...styles.textFine }}>
                        Defina o tempo no qual seus dados são coletados.
                      </Typography>
                    </Stack>
                    <Stack gap={2} mt={2} direction={"row"}>
                      <Stack
                        justifyContent={"center"}
                        direction={"row"}
                        gap={1}
                      >
                        <TextField
                          onChange={(e) => {
                            setCheckChanges(true);
                            setValorTempoFtth(
                              e.target.value >= 0 && e.target.value < 60
                                ? e.target.value
                                : e.target.value < 0
                                ? 1
                                : 59
                            );
                          }}
                          error={valorTempoFtth < 1}
                          value={valorTempoFtth != null ? valorTempoFtth : 5}
                          sx={[styles.textField, { width: "100px" }]}
                          size="small"
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography sx={styles.textAdorment}>
                                    TEMPO
                                  </Typography>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />

                        <TextField
                          select
                          onChange={(e) => {
                            setCheckChanges(true);
                            setUnidadeTempoFtth(e.target.value);
                          }}
                          value={unidadeTempoFtth ? unidadeTempoFtth : 60000}
                          sx={[styles.textField, { width: "fit-content" }]}
                          size="small"
                        >
                          {/* <MenuItem value={1000}>segundo(s)</MenuItem> */}
                          <MenuItem value={60000}>minuto(s)</MenuItem>
                          <MenuItem value={3600000}>hora(s)</MenuItem>
                        </TextField>
                      </Stack>
                    </Stack>
                    {valorTempoFtth < 1 && (
                      <Alerts
                        sx={{ mt: 1 }}
                        severity="error"
                        message="Tempo de coleta invalido!"
                      ></Alerts>
                    )}
                  </Box>
                </>
              )}
            </Stack>
            <Stack
              direction={"row"}
              sx={{ bottom: "10px", right: "10px", position: "absolute" }}
            >
              <Button
                onClick={() => {
                  if (!checkChanges) {
                    handleClose();
                    return;
                  }
                  setOpenAlertModal({
                    open: true,
                    callback: handleClose,
                    severity: "error",
                    severity: "warning",
                    text: "As alterações feitas não foram salvas. Se prosseguir com a operação as mudanças serão perdidas.",
                  });
                }}
                sx={styles.buttomEnd}
                startIcon={<CloseOutlined />}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleApiUpdateConfig}
                sx={styles.buttomEnd}
                color="white"
                startIcon={<Check />}
                disabled={
                  !checkChanges || !linkFTTHVerification || !updatesTimes()
                }
              >
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <AlertModal
        openModal={openAlertModal?.open ?? false}
        setOpenModal={(data) => setOpenAlertModal({ open: data })}
        buttonText={openAlertModal?.buttonText}
        buttonCancelText={openAlertModal?.buttonCancelText}
        confirmar={false}
        handleButton={() => {
          setOpenAlertModal({ open: false });
          if (openAlertModal.callback) {
            openAlertModal.callback(...(openAlertModal?.params || []));
            return;
          }
        }}
        severity={openAlertModal?.severity}
        singleButton={false}
        textContent={openAlertModal?.text}
      />
    </>
  );
}
